import { Box, Container, Divider, PageBody, Paper, Typography } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { isNotNull } from '../../../../utils/typeUtils'
import { FormTemplatesQuery, useFormTemplatesQuery } from '../../../forms/gql/templates_gen'
import { SubmissionRelation } from '../../../graphql/generated.types'
import { useOrgDashboardQuery } from '../../../orgDashboard/homepage/gql/orgDashboard_gen'
import {
  FormsAnswerFragment,
  FormsQuestionFragment,
  FormsSubmissionFragment,
  useCurrentUserQuery,
} from '../../../user/gql/currentUser_gen'
import { FormManager } from './FormManager'
import { QAPair } from './types'

export const OrgSurvey = () => {
  const templateType = 'org_survey'
  const { data: currentUserData } = useCurrentUserQuery()
  const { isLoading, data: formTemplatesData } = useFormTemplatesQuery({ templateType })
  const { data: orgData } = useOrgDashboardQuery()
  const orgId = orgData?.organization?.id
  const userId = currentUserData?.currentUser?.id
  if (!userId || !orgId || !formTemplatesData) return null // if there is no formTemplate data, then we have no active templates for this survey
  if (isLoading) return null

  const submission = currentUserData?.currentUser?.formSubmissions?.find(
    (submission) => submission.template?.templateType === templateType && submission.subjectId === orgId
  )

  type FormData = {
    displayOrder: Array<number>
    questions: Array<FormsQuestionFragment>
    answers: Array<FormsAnswerFragment>
    templateId: number
  }

  const getFormData = (
    formTemplatesData: FormTemplatesQuery,
    submission: FormsSubmissionFragment | undefined
  ): FormData => {
    const template = formTemplatesData.formTemplate
    const templateId = submission?.template.id || template?.id
    const displayOrder = submission?.template.displayOrder || template?.displayOrder
    if (!template || !displayOrder || !templateId) {
      throw new Error('Error loading survey, please try refreshing the page.')
    }
    return {
      displayOrder,
      questions: submission?.questions || template?.questions,
      answers: submission?.answers || [],
      templateId,
    }
  }

  const { displayOrder, questions, answers, templateId } = getFormData(formTemplatesData, submission)

  const combinedData: QAPair[] = questions.map((q) => ({
    question: q,
    answer: answers?.find((a) => a.questionId === q.id),
  }))
  const sortedCombinedData = displayOrder
    .map((id) => combinedData.find((item) => item.question.id === id))
    .filter(isNotNull)

  const formSubject = { id: orgId, type: SubmissionRelation.Organization }

  return (
    <PageBody>
      <Container maxWidth="md">
        <Paper elevation={3} sx={{ p: 4 }}>
          <Box>
            <Box display="flex" justifyContent="center" width="100%" mb={3}>
              <Typography
                variant="h2"
                fontWeight="light"
                gutterBottom
                align="center"
                sx={{ maxWidth: '80%' }}
              >
                Tell us about your company, team, and goals.
              </Typography>
            </Box>
            <Divider />
            <FormManager
              pageSize={4}
              QAList={sortedCombinedData}
              submission={submission}
              subject={formSubject}
              templateId={templateId}
            />
          </Box>
        </Paper>
      </Container>
    </PageBody>
  )
}
