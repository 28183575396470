import * as Types from '../../graphql/generated.types'

import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { fetchGql } from '@alice-financial/api'
export type CurrentUserQueryVariables = Types.Exact<{ [key: string]: never }>

export type CurrentUserQuery = {
  __typename?: 'Query'
  currentUser?: {
    __typename?: 'User'
    id: number
    firstName?: string | null
    lastName?: string | null
    preferredName?: string | null
    email?: string | null
    languagePreference: Types.LanguagePreference
    birthdate?: string | null
    isEmailConfirmed: boolean
    helpScoutSecureBeaconToken?: string | null
    tel?: {
      __typename?: 'Telephone'
      nationalNumber: string
      isSmsConfirmed?: boolean | null
      isConfirmationCodeSent?: boolean | null
    } | null
    provisionalOrganizations: Array<{ __typename?: 'ProvisionalOrganization'; id: number }>
    formSubmissions?: Array<{
      __typename?: 'FormsSubmission'
      id: number
      status?: string | null
      subjectType: string
      subjectId: number
      ownerId?: number | null
      ownerType?: string | null
      template: {
        __typename?: 'FormsTemplate'
        id: number
        displayOrder: Array<number>
        templateType: string
      }
      questions: Array<{
        __typename?: 'FormsQuestion'
        id: number
        options?: Array<string> | null
        questionType: Types.QuestionType
        text: string
        subtitle?: string | null
        placeholder?: string | null
      }>
      answers: Array<{ __typename?: 'FormsAnswer'; id: number; questionId: number; text?: any | null }>
    }> | null
  } | null
}

export type GqlUserFragment = {
  __typename?: 'User'
  id: number
  firstName?: string | null
  lastName?: string | null
  preferredName?: string | null
  email?: string | null
  languagePreference: Types.LanguagePreference
  birthdate?: string | null
  isEmailConfirmed: boolean
  helpScoutSecureBeaconToken?: string | null
  tel?: {
    __typename?: 'Telephone'
    nationalNumber: string
    isSmsConfirmed?: boolean | null
    isConfirmationCodeSent?: boolean | null
  } | null
  provisionalOrganizations: Array<{ __typename?: 'ProvisionalOrganization'; id: number }>
  formSubmissions?: Array<{
    __typename?: 'FormsSubmission'
    id: number
    status?: string | null
    subjectType: string
    subjectId: number
    ownerId?: number | null
    ownerType?: string | null
    template: { __typename?: 'FormsTemplate'; id: number; displayOrder: Array<number>; templateType: string }
    questions: Array<{
      __typename?: 'FormsQuestion'
      id: number
      options?: Array<string> | null
      questionType: Types.QuestionType
      text: string
      subtitle?: string | null
      placeholder?: string | null
    }>
    answers: Array<{ __typename?: 'FormsAnswer'; id: number; questionId: number; text?: any | null }>
  }> | null
}

export type FormsSubmissionFragment = {
  __typename?: 'FormsSubmission'
  id: number
  status?: string | null
  subjectType: string
  subjectId: number
  ownerId?: number | null
  ownerType?: string | null
  template: { __typename?: 'FormsTemplate'; id: number; displayOrder: Array<number>; templateType: string }
  questions: Array<{
    __typename?: 'FormsQuestion'
    id: number
    options?: Array<string> | null
    questionType: Types.QuestionType
    text: string
    subtitle?: string | null
    placeholder?: string | null
  }>
  answers: Array<{ __typename?: 'FormsAnswer'; id: number; questionId: number; text?: any | null }>
}

export type FormsQuestionFragment = {
  __typename?: 'FormsQuestion'
  id: number
  options?: Array<string> | null
  questionType: Types.QuestionType
  text: string
  subtitle?: string | null
  placeholder?: string | null
}

export type FormsAnswerFragment = {
  __typename?: 'FormsAnswer'
  id: number
  questionId: number
  text?: any | null
}

export const FormsQuestionFragmentDoc = `
    fragment FormsQuestion on FormsQuestion {
  id
  options
  questionType
  text
  subtitle
  placeholder
}
    `
export const FormsAnswerFragmentDoc = `
    fragment FormsAnswer on FormsAnswer {
  id
  questionId
  text
}
    `
export const FormsSubmissionFragmentDoc = `
    fragment FormsSubmission on FormsSubmission {
  id
  status
  subjectType
  subjectId
  ownerId
  ownerType
  template {
    id
    displayOrder
    templateType
  }
  questions {
    ...FormsQuestion
  }
  answers {
    ...FormsAnswer
  }
}
    `
export const GqlUserFragmentDoc = `
    fragment GqlUser on User {
  id
  firstName
  lastName
  preferredName
  email
  languagePreference
  birthdate
  isEmailConfirmed
  tel {
    nationalNumber
    isSmsConfirmed
    isConfirmationCodeSent
  }
  helpScoutSecureBeaconToken
  provisionalOrganizations {
    id
  }
  formSubmissions {
    ...FormsSubmission
  }
}
    `
export const CurrentUserDocument = `
    query CurrentUser {
  currentUser {
    ...GqlUser
  }
}
    ${GqlUserFragmentDoc}
${FormsSubmissionFragmentDoc}
${FormsQuestionFragmentDoc}
${FormsAnswerFragmentDoc}`

export const useCurrentUserQuery = <TData = CurrentUserQuery, TError = Error>(
  variables?: CurrentUserQueryVariables,
  options?: UseQueryOptions<CurrentUserQuery, TError, TData>
) => {
  return useQuery<CurrentUserQuery, TError, TData>(
    variables === undefined ? ['CurrentUser'] : ['CurrentUser', variables],
    fetchGql<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, variables),
    options
  )
}

useCurrentUserQuery.getKey = (variables?: CurrentUserQueryVariables) =>
  variables === undefined ? ['CurrentUser'] : ['CurrentUser', variables]
