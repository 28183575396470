import memoize from 'memoize-one'
import { AddressDisplay } from '../../../graphql/generated.types'
import { useCurrentUserQuery } from '../../../user/gql/currentUser_gen'
import { USER_QUERY_KEY } from '../../../user/useQueryUser'
import { useEmployeeQuery } from '../../gql/employee_gen'
import { AddressAttributes } from './types'

export const profileAddressToAddressAttributes = memoize(
  (profileAddress: Partial<AddressDisplay> | null | undefined): AddressAttributes => {
    const { line1, line2, cityName, stateCode, zipcode, isVerified } = profileAddress ?? {}
    return {
      line_1: line1 || '',
      line_2: line2 || '',
      city_name: cityName || '',
      state_code: stateCode || '',
      zipcode: zipcode || '',
      is_verified: isVerified || false,
    }
  }
)

export const useQueryAddress = () => {
  const { data: currentUserData, ...queryStatus } = useCurrentUserQuery()
  const { data: employeeData } = useEmployeeQuery()
  return {
    data: profileAddressToAddressAttributes(employeeData?.employee?.mailingAddress),
    ...queryStatus,
  }
}
useQueryAddress.getKey = () => USER_QUERY_KEY
