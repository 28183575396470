import * as Types from '../../../../graphql/generated.types'

import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { fetchGql } from '@alice-financial/api'
export type ProvisionAliceCardMutationVariables = Types.Exact<{
  input: Types.ProvisionAliceCardInput
}>

export type ProvisionAliceCardMutation = {
  __typename?: 'Mutation'
  provisionAliceCard?: {
    __typename?: 'ProvisionAliceCardPayload'
    employee?: {
      __typename?: 'Employee'
      id: number
      spendingConnectionInfo: { __typename?: 'SpendingConnectionInfo'; hasAcceptedAliceCardTos: boolean }
    } | null
    inputErrors: Array<{ __typename?: 'InputError'; argument: string; message: string }>
  } | null
}

export const ProvisionAliceCardDocument = `
    mutation ProvisionAliceCard($input: ProvisionAliceCardInput!) {
  provisionAliceCard(input: $input) {
    employee {
      id
      spendingConnectionInfo {
        hasAcceptedAliceCardTos
      }
    }
    inputErrors {
      argument
      message
    }
  }
}
    `

export const useProvisionAliceCardMutation = <TError = Error, TContext = unknown>(
  options?: UseMutationOptions<
    ProvisionAliceCardMutation,
    TError,
    ProvisionAliceCardMutationVariables,
    TContext
  >
) => {
  return useMutation<ProvisionAliceCardMutation, TError, ProvisionAliceCardMutationVariables, TContext>(
    ['ProvisionAliceCard'],
    (variables?: ProvisionAliceCardMutationVariables) =>
      fetchGql<ProvisionAliceCardMutation, ProvisionAliceCardMutationVariables>(
        ProvisionAliceCardDocument,
        variables
      )(),
    options
  )
}
