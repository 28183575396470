import * as Types from '../../graphql/generated.types'

import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { fetchGql } from '@alice-financial/api'
export type EmployeeQueryVariables = Types.Exact<{ [key: string]: never }>

export type EmployeeQuery = {
  __typename?: 'Query'
  employee?: {
    __typename?: 'Employee'
    id: number
    tosAcceptedAt?: string | null
    employmentStartDate?: string | null
    role: Types.EmployeeRole
    enrollmentInfo: { __typename?: 'EmployeeEnrollment'; enrollmentDate?: string | null }
    spendingInfo: {
      __typename?: 'SpendingInfo'
      hasRecentAutoConfirmedTxns: boolean
      totalEstimatedPretaxSavings?: number | null
    }
    mailingAddress?: {
      __typename?: 'AddressDisplay'
      isVerified: boolean
      line1: string
      line2?: string | null
      cityName: string
      stateCode: string
      zipcode: string
    } | null
    employer: {
      __typename?: 'Employer'
      id: number
      name?: string | null
      marketingFriendlyName?: string | null
      inviteCode: string
      offboardingDate?: string | null
      organization?: {
        __typename?: 'Organization'
        id: number
        name: string
        aliceCardProgram: { __typename?: 'AliceCardProgram'; cardIssuingStatus: Types.AliceCardIssuingStatus }
      } | null
    }
  } | null
}

export type EmployeeFragment = {
  __typename?: 'Employee'
  id: number
  tosAcceptedAt?: string | null
  employmentStartDate?: string | null
  role: Types.EmployeeRole
  enrollmentInfo: { __typename?: 'EmployeeEnrollment'; enrollmentDate?: string | null }
  spendingInfo: {
    __typename?: 'SpendingInfo'
    hasRecentAutoConfirmedTxns: boolean
    totalEstimatedPretaxSavings?: number | null
  }
  mailingAddress?: {
    __typename?: 'AddressDisplay'
    isVerified: boolean
    line1: string
    line2?: string | null
    cityName: string
    stateCode: string
    zipcode: string
  } | null
  employer: {
    __typename?: 'Employer'
    id: number
    name?: string | null
    marketingFriendlyName?: string | null
    inviteCode: string
    offboardingDate?: string | null
    organization?: {
      __typename?: 'Organization'
      id: number
      name: string
      aliceCardProgram: { __typename?: 'AliceCardProgram'; cardIssuingStatus: Types.AliceCardIssuingStatus }
    } | null
  }
}

export const EmployeeFragmentDoc = `
    fragment Employee on Employee {
  id
  tosAcceptedAt
  enrollmentInfo {
    enrollmentDate
  }
  employmentStartDate
  role
  spendingInfo {
    hasRecentAutoConfirmedTxns
    totalEstimatedPretaxSavings
  }
  mailingAddress {
    isVerified
    line1
    line2
    cityName
    stateCode
    zipcode
  }
  employer {
    id
    name
    marketingFriendlyName
    inviteCode
    offboardingDate
    organization {
      id
      name
      aliceCardProgram {
        cardIssuingStatus
      }
    }
  }
}
    `
export const EmployeeDocument = `
    query Employee {
  employee {
    ...Employee
  }
}
    ${EmployeeFragmentDoc}`

export const useEmployeeQuery = <TData = EmployeeQuery, TError = Error>(
  variables?: EmployeeQueryVariables,
  options?: UseQueryOptions<EmployeeQuery, TError, TData>
) => {
  return useQuery<EmployeeQuery, TError, TData>(
    variables === undefined ? ['Employee'] : ['Employee', variables],
    fetchGql<EmployeeQuery, EmployeeQueryVariables>(EmployeeDocument, variables),
    options
  )
}

useEmployeeQuery.getKey = (variables?: EmployeeQueryVariables) =>
  variables === undefined ? ['Employee'] : ['Employee', variables]
