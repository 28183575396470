import {
  AliceCard,
  AliceCardAccountStatus,
  AliceCardPlatform,
  AliceCardStatus,
  AliceCardType,
  CardRequestTypeEnum,
} from '../../../graphql/generated.types'
import { AliceCardFragment } from '../gql/connectedCardsQuery_gen'
import { CombinedAliceCardAccount, useQuerySpendingConnections } from '../useQuerySpendingConnections'
import { SortableAliceCard } from './types'

export const DISPLAY_ACCOUNT_STATUSES = [AliceCardAccountStatus.Active, AliceCardAccountStatus.BalanceGated]

// 'status' outweighs 'cardType'
const STATUS_WEIGHTS: Record<AliceCardStatus, number> = {
  [AliceCardStatus.Inactive]: 40, // Inactive usually means "on its way to you" - first thing user needs to attend to
  [AliceCardStatus.Active]: 30,
  [AliceCardStatus.Canceled]: 20, // expired?
  [AliceCardStatus.Terminated]: 10, // usually means 'reported missing'
}

// Generally want to show 'instant' cards before 'physical'
const TYPE_WEIGHTS: Record<AliceCardType, number> = {
  [AliceCardType.Instant]: 1,
  [AliceCardType.Physical]: 0,
  [AliceCardType.Virtual]: -1,
}

/**
 * Instant cards on top, then ranked within `cardType` based on status weights defined in `STATUS_WEIGHTS`
 */
export const sortCards = <TCard extends SortableAliceCard>(cardA: TCard, cardB: TCard): number => {
  const cardBweight = TYPE_WEIGHTS[cardB.cardType] + STATUS_WEIGHTS[cardB.status]
  const cardAweight = TYPE_WEIGHTS[cardA.cardType] + STATUS_WEIGHTS[cardA.status]
  return cardBweight - cardAweight
}

export const formatExpiryDate = (expiryDate: AliceCard['expiryDate']) => {
  const date = new Date(expiryDate)
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const year = (date.getFullYear() % 100).toString().padStart(2, '0')
  return `${month}/${year}`
}

export const isIssued = (c: Pick<AliceCardFragment, 'status' | 'reportedMissingAt'>) =>
  !c.reportedMissingAt && [AliceCardStatus.Inactive, AliceCardStatus.Active].includes(c.status)

type MaybeAvailableAliceCard = Pick<AliceCardFragment, 'status' | 'reportedMissingAt' | 'cardType'>
const getAvailableCard =
  (cardType: AliceCardType) =>
  <TCard extends MaybeAvailableAliceCard>(cards?: Array<TCard>) =>
    cards && cards.find((c) => c.cardType === cardType && isIssued(c))

/**
 * This function checks to make sure an _issued_ instant card is in the list of passed-in cards, e.g.
 * to check that a list of the user's Alice Cards contains a usable Instant Card
 */
export const getAvailableInstantAliceCard = getAvailableCard(AliceCardType.Instant)
export const getAvailablePhysicalAliceCard = getAvailableCard(AliceCardType.Physical)

/**
 * Boolean check for whether a card is 'dead' - i.e. no longer usable
 */
export const isCardDead = (card: Pick<AliceCardFragment, 'status' | 'reportedMissingAt'>) =>
  card.status === AliceCardStatus.Terminated ||
  card.status === AliceCardStatus.Canceled ||
  Boolean(card.reportedMissingAt)

const isInitialAliceCardRequestPending = (aliceCardAccount?: CombinedAliceCardAccount) => {
  const cards = aliceCardAccount?.aliceCards || []
  return Boolean(
    aliceCardAccount && DISPLAY_ACCOUNT_STATUSES.includes(aliceCardAccount.status) && cards.length === 0
  )
}

/**
 * Determine what kind of Alice card request is allowed based on what cards are passed in.
 * Essentially, if the user already has an issued Instant card, they can't request a new one,
 * and if they already have an issued Physical card, they can't request a new one.
 *
 * If there are no cards, they can request both - see unit tests for more examples
 */
export const useAliceCardRequestScope = (platform = AliceCardPlatform.Stripe): CardRequestTypeEnum | null => {
  const {
    data: { aliceCardAccounts },
  } = useQuerySpendingConnections()
  if (aliceCardAccounts?.some(isInitialAliceCardRequestPending)) return null
  const accountForRequest = findActiveCardAccount(aliceCardAccounts, platform)
  const cards = accountForRequest?.aliceCards || []

  const mayRequestPhysicalCard = !getAvailablePhysicalAliceCard(cards)
  const mayRequestInstantCard = !getAvailableInstantAliceCard(cards)

  if (mayRequestPhysicalCard && mayRequestInstantCard) return CardRequestTypeEnum.InstantAndPhysical
  if (mayRequestInstantCard && !mayRequestPhysicalCard) return CardRequestTypeEnum.Instant
  if (mayRequestPhysicalCard && !mayRequestInstantCard) return CardRequestTypeEnum.Physical

  return null
}

export const findActiveCardAccount = (
  aliceCardAccounts: Array<CombinedAliceCardAccount> | undefined,
  platform = AliceCardPlatform.Stripe
) =>
  aliceCardAccounts?.find(
    (account) =>
      [AliceCardAccountStatus.Active, AliceCardAccountStatus.BalanceGated].includes(account.status) &&
      account.platform === platform
  )

export const isAliceCardRequestPending = (aliceCardAccount: CombinedAliceCardAccount | undefined) =>
  aliceCardAccount?.status === AliceCardAccountStatus.Active && aliceCardAccount.aliceCards.length === 0

export const isAliceCardAccountBalanceGated = (aliceCardAccount: CombinedAliceCardAccount | undefined) =>
  aliceCardAccount?.status === AliceCardAccountStatus.BalanceGated
