import {
  Alert,
  Box,
  Grid,
  List,
  PageBody,
  Paper,
  SectionHeading,
  Typography,
} from '@alice-financial/pretext-ui'
import * as React from 'react'
import { useOrgDashboardQuery } from '../homepage/gql/orgDashboard_gen'
import { AliceCardProgramHealthcheck } from './AliceCardProgramHealthcheck'
import { useMayManageAliceCardProgram } from './aliceCardProgramUtils'
import { ConnectedPaymentMethods } from './ConnectedPaymentMethods'
import { ConnectPaymentMethodStep } from './ConnectPaymentMethodStep'
import { FundingAccountBalance } from './FundingAccountComponents'
import { AliceCardProgramSetup } from './setup/AliceCardProgramSetup'

export const AliceCardProgramPage = () => {
  const mayManageAliceCardProgram = useMayManageAliceCardProgram()
  const { data: orgData } = useOrgDashboardQuery()
  const org = orgData?.organization
  if (!org) return null
  if (!mayManageAliceCardProgram) return null

  return (
    <PageBody maxWidth={800}>
      <Typography variant="h1">Benefits Administration Account</Typography>
      <Paper sx={{ my: 3 }}>
        <List disablePadding>
          <AliceCardProgramHealthcheck org={org} />
        </List>
      </Paper>

      {org.aliceCardProgram.hasFundingConnected && (
        <Box mt={3} mb={4}>
          <SectionHeading to="/manage/alice-card-program/account" toLabel="See account activity">
            Alice Card Funding Account
          </SectionHeading>
          <Grid container columnGap={2} wrap="nowrap" flexDirection="row-reverse" sx={{ marginTop: 2 }}>
            <Alert severity="info" icon={false} sx={{ paddingTop: 0.5, paddingBottom: 0.5 }}>
              Employee spend on Alice Card is automatically transferred regularly from your payroll bank
              account to your Alice Card Funding Account. Transfers made to the Alice Card Funding Account{' '}
              <em>are not Alice&apos;s fees</em>, and the funds do not belong to Alice &ndash; they belong to
              your company&apos;s commuter benefit plan.
            </Alert>
            <Grid item minWidth="25%">
              <FundingAccountBalance org={org} />
            </Grid>
          </Grid>
        </Box>
      )}

      <Typography variant="h2">Payment method</Typography>
      <Box my={3}>
        <ConnectedPaymentMethods org={org} />
      </Box>
      {org.aliceCardProgram.hasFundingConnected ? (
        <ConnectPaymentMethodStep org={org} />
      ) : (
        <AliceCardProgramSetup org={org} />
      )}
    </PageBody>
  )
}
