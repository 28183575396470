import { GqlInputErrorException, getGqlInputErrorHandler } from '@alice-financial/api'
import {
  ActionLayout,
  Button,
  Callout,
  CheckboxController,
  Container,
  Typography,
} from '@alice-financial/pretext-ui'
import * as React from 'react'
import { useForm } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { AliceCardTosLabel } from '../../../enrollment/AliceCardTosLabel'
import { AliceCardPlatform, CardRequestTypeEnum } from '../../../graphql/generated.types'
import { useQuerySpendingConnections, useSpendingConnectionSummary } from '../useQuerySpendingConnections'
import {
  findActiveCardAccount,
  getAvailableInstantAliceCard,
  getAvailablePhysicalAliceCard,
} from './aliceCardUtils'
import { OrderAliceCardFormValues } from './types'
import { useRequestAliceCard } from './useRequestAliceCard'

export const useMayMigrateSynapseToStripe = () => {
  const { hasActiveSynapseAccount, hasAcceptedAliceCardTos } = useSpendingConnectionSummary()
  const provisioningScope = useMigrationProvisioningScope()

  return hasActiveSynapseAccount && !hasAcceptedAliceCardTos && Boolean(provisioningScope)
}

/**
 * When migrating from Synapse, we want to provision the same card type(s) that the user
 * already has
 */
const useMigrationProvisioningScope = () => {
  const {
    data: { aliceCardAccounts },
  } = useQuerySpendingConnections()

  const activeSynapseAccount = findActiveCardAccount(aliceCardAccounts, AliceCardPlatform.Synapse)

  const instantCard = getAvailableInstantAliceCard(activeSynapseAccount?.aliceCards)
  const physicalCard = getAvailablePhysicalAliceCard(activeSynapseAccount?.aliceCards)

  if (!activeSynapseAccount) return null
  if (!instantCard && !physicalCard) return CardRequestTypeEnum.InstantAndPhysical

  if (!physicalCard) return CardRequestTypeEnum.Instant

  // if they have only physical card or both, issue both instant and physical
  return CardRequestTypeEnum.InstantAndPhysical
}

/**
 * Users who currently have a Synapse card can migrate to a Stripe card by accepting
 * the new terms of service.
 */
export const MigrateSynapseToStripeForm = () => {
  const intl = useIntl()
  const mayMigrateSynapseToStripe = useMayMigrateSynapseToStripe()
  const { hasAcceptedAliceCardTos } = useSpendingConnectionSummary()
  const cardTypesRequested = useMigrationProvisioningScope()
  const { handleSubmit, control, setError } = useForm<Pick<OrderAliceCardFormValues, 'tosAccepted'>>({
    values: { tosAccepted: Boolean(hasAcceptedAliceCardTos) },
  })
  const gqlInputErrorHandler = getGqlInputErrorHandler(setError)
  const {
    mutate: requestAliceCard,
    isLoading: isRequestingAliceCard,
    isSuccess,
  } = useRequestAliceCard({
    onError: (error) => {
      if (error instanceof GqlInputErrorException) gqlInputErrorHandler(error)
    },
  })

  if (!mayMigrateSynapseToStripe || !cardTypesRequested) return null

  const onSubmit = handleSubmit((values) => requestAliceCard({ input: { ...values, cardTypesRequested } }))
  const isSubmitDisabled = isRequestingAliceCard || isSuccess

  return (
    <Container sx={{ padding: 0 }}>
      <Callout component="div" gutterBottom>
        <Typography variant="h2" gutterBottom>
          <FormattedMessage id="cards.alice.synapse_expiring" />
        </Typography>
        <Typography gutterBottom>
          <FormattedMessage id="cards.alice.synapse_expiring_description" />
        </Typography>
        <form onSubmit={onSubmit}>
          <CheckboxController
            color="primary"
            name="tosAccepted"
            control={control}
            rules={{
              validate: (v) =>
                v === true || intl.formatMessage({ id: 'cards.alice.confirm_you_read_the_documents' }),
            }}
            label={<AliceCardTosLabel />}
          />
          <ActionLayout
            disablePadding
            marginTop={1}
            primary={
              <Button type="submit" variant="contained" color="primary" fullWidth disabled={isSubmitDisabled}>
                <FormattedMessage id="cards.alice.get_new_card" />
              </Button>
            }
          ></ActionLayout>
        </form>
      </Callout>
    </Container>
  )
}
