import { Button, Container, styled } from '@alice-financial/pretext-ui'
import PrintIcon from '@mui/icons-material/Print'
// import SendIcon from '@mui/icons-material/Send'
import * as React from 'react'
// import { useMutationNotifier } from '../../utils/useMutationNotifier'
// import { useQueryUser } from '../user'
import { PageBody } from '../routes/PageBody'
import { DocDisplay } from './DocDisplay'
import { EmailDocName } from './types'
// import { useSendDocument } from './useSendDocument'

// Temporally disabling per ENG-9140
// type EmailButtonProps = {
//   docName: EmailDocName
// }
// const EmailButton = ({ docName }: EmailButtonProps) => {
//   const { data: user } = useQueryUser()

//   const mutationOptions = useMutationNotifier({
//     onMutate: `Sending to ${user?.email}`,
//     onSuccess: 'Document sent successfully!',
//   })
//   const { mutate: send } = useSendDocument(mutationOptions)
//   return (
//     <Button variant="contained" onClick={() => send(docName)} endIcon={<SendIcon />}>
//       Send by email
//     </Button>
//   )
// }

const ButtonPosition = styled(Container)({
  position: 'sticky',
  top: 0,
  textAlign: 'right',
  paddingTop: '0.5em',
  '@media print': {
    display: 'none',
  },
})

const PrintButton = () => (
  <Button variant="contained" onClick={() => window.print()} endIcon={<PrintIcon />}>
    Print
  </Button>
)

type DocRouteProps = {
  docHtml: string
  docName?: EmailDocName
}
/**
 * A route that will display a document. If `docName` is provided, a logged-in user will be able
 * to email the document to themselves.
 */
export const DocRoute = ({ docHtml }: DocRouteProps) => {
  return (
    <PageBody>
      <ButtonPosition>{<PrintButton />}</ButtonPosition>
      <DocDisplay docHtml={docHtml} />
    </PageBody>
  )
}
