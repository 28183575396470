import { AddressInput, AddressDisplay, WorkLocation } from '../../graphql/generated.types'
import { LocationData } from './useManageWorkLocation'
import { WorkLocationFormInputs } from './types'

type PlacesAPIAddress = Omit<AddressInput, 'mustVerify'> & {
  streetNumber: string
}

const ADDRESS_COMPONENT_TYPE_TO_ADDRESS_HASH_KEY: Record<string, keyof PlacesAPIAddress> = {
  street_number: 'streetNumber',
  street_address: 'line1',
  route: 'line1',
  administrative_area_level_1: 'stateCode',
  locality: 'cityName',
  sublocality: 'cityName',
  sublocality_level_1: 'cityName',
  sublocality_level_2: 'cityName',
  sublocality_level_3: 'cityName',
  sublocality_level_4: 'cityName',
  postal_code: 'zipcode',
}

const findCorrespondingAddressKey = (
  component: google.maps.GeocoderAddressComponent
): keyof PlacesAPIAddress | null => {
  for (const [componentType, hashKey] of Object.entries(ADDRESS_COMPONENT_TYPE_TO_ADDRESS_HASH_KEY)) {
    if (component.types.includes(componentType)) return hashKey
  }
  return null
}

const convertAddressComponentsToAddressHash = (addressComponents: google.maps.GeocoderAddressComponent[]) =>
  addressComponents.reduce<PlacesAPIAddress>(
    (acc, component) => {
      const addressKey = findCorrespondingAddressKey(component)
      if (addressKey) {
        acc[addressKey] = component.short_name
      }
      return acc
    },
    { streetNumber: '', zipcode: '', line1: '', line2: '', cityName: '', stateCode: '' } as PlacesAPIAddress
  )

const mapToAddress = (address: PlacesAPIAddress): AddressInput => {
  const { streetNumber, line1, line2, cityName, stateCode, zipcode } = address
  return {
    cityName,
    line1: `${streetNumber} ${line1}`.trim(),
    line2,
    stateCode,
    zipcode,
  }
}

export const convertPlaceToLocationInput = (
  place: google.maps.places.PlaceResult
): LocationData | undefined => {
  if (!place.address_components || !place.name || !place.geometry || !place.geometry.location) return

  const address = mapToAddress(convertAddressComponentsToAddressHash(place.address_components))

  const locationInput = {
    name: place.name,
    latitude: place.geometry.location.lat(),
    longitude: place.geometry.location.lng(),
    address,
  }
  return locationInput
}

export const convertWorkLocationToFormInputs = (
  workLocation: WorkLocation | undefined
): WorkLocationFormInputs => {
  if (!workLocation) {
    return {
      name: '',
      address: {
        line1: '',
        line2: '',
        cityName: '',
        stateCode: '',
        zipcode: '',
      },
      latitude: undefined,
      longitude: undefined,
      logo: null,
    }
  }
  return {
    id: workLocation.id.toString(),
    name: workLocation.name,
    nickname: workLocation.nickname || undefined,
    address: convertAddressDisplayToAddress(workLocation.address),
    latitude: workLocation.latitude || undefined,
    longitude: workLocation.longitude || undefined,
    logo: null, // logo can be replaced, but we don't assign the old one to this value
  }
}
export const convertAddressDisplayToAddress = (addressDisplay: AddressDisplay): AddressInput => {
  return {
    line1: addressDisplay.line1,
    line2: addressDisplay.line2 || '',
    cityName: addressDisplay.cityName,
    stateCode: addressDisplay.stateCode,
    zipcode: addressDisplay.zipcode,
  }
}
