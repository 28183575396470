import { ActionLayout, Button, Container, Typography } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { useErrorBoundary } from 'react-error-boundary'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import { ContactSupportButton } from '../employeeDashboard/ContactSupport'
import { BreadcrumbProps } from './Breadcrumb'
import { PageBody } from './PageBody'

type ErrorDisplayProps = { returnTo?: BreadcrumbProps; children?: React.ReactNode }
export const ErrorDisplay = ({ returnTo, children }: ErrorDisplayProps) => {
  const { resetBoundary } = useErrorBoundary()
  return (
    <PageBody>
      <Container>
        <Typography variant="h1" gutterBottom>
          <FormattedMessage id="error_display.heading" />
        </Typography>
        {children || (
          <Typography gutterBottom>
            <FormattedMessage id="error_display.info" />
          </Typography>
        )}
        <ActionLayout
          primary={
            returnTo && (
              <Button
                variant="contained"
                component={Link}
                to={returnTo.route}
                onClick={resetBoundary}
                fullWidth
              >
                <FormattedMessage id="common.go_back_to_label" values={{ label: returnTo.label }} />
              </Button>
            )
          }
          secondary={<ContactSupportButton variant="outlined" />}
        />
      </Container>
    </PageBody>
  )
}
