import * as Types from '../../../graphql/generated.types'

import {
  BankingTransactionFragmentDoc,
  ReimbursementClaimFragmentDoc,
} from '../../../graphql/fragments/BankingTransactionFragment_gen'
import { BankingAccountFragmentDoc } from '../../../graphql/fragments/BankingAccountFragment_gen'
import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { fetchGql } from '@alice-financial/api'
export type BankingTransactionQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input']
}>

export type BankingTransactionQuery = {
  __typename?: 'Query'
  bankingTransaction: {
    __typename?: 'BankingTransaction'
    id: string
    amount: number
    date: string
    estimatedSavings: number
    name: string
    merchantName?: string | null
    spendEligibility: {
      __typename?: 'SpendEligibility'
      confirmedPartialAmount?: number | null
      pretaxCategory: Types.PretaxCategory
      autoConfirmedAt?: string | null
      isDisconfirmable?: boolean | null
      confirmationStatus: {
        __typename?: 'ConfirmationStatus'
        updatedAt?: string | null
        value: Types.ConfirmationStatusValue
      }
    }
    account:
      | {
          __typename: 'BankingAccount'
          id: number
          billingSystemId?: string | null
          name?: string | null
          cardType?: Types.BankCardType | null
          accountType?: Types.BankingAccountType | null
          platform: Types.BankingAccountPlatform
          accountLast4?: string | null
        }
      | { __typename: 'CashPaymentAccount' }
    reimbursementClaim?: {
      __typename?: 'ReimbursementClaim'
      approvalStatus: Types.ReimbursementClaimApprovalStatus
      reviewedAt?: string | null
    } | null
    reimbursementStatus?: { __typename?: 'ReimbursementStatus'; isFullyReimbursed: boolean } | null
  }
}

export const BankingTransactionDocument = `
    query BankingTransaction($id: ID!) {
  bankingTransaction(id: $id) {
    ...BankingTransaction
  }
}
    ${BankingTransactionFragmentDoc}
${BankingAccountFragmentDoc}
${ReimbursementClaimFragmentDoc}`

export const useBankingTransactionQuery = <TData = BankingTransactionQuery, TError = Error>(
  variables: BankingTransactionQueryVariables,
  options?: UseQueryOptions<BankingTransactionQuery, TError, TData>
) => {
  return useQuery<BankingTransactionQuery, TError, TData>(
    ['BankingTransaction', variables],
    fetchGql<BankingTransactionQuery, BankingTransactionQueryVariables>(
      BankingTransactionDocument,
      variables
    ),
    options
  )
}

useBankingTransactionQuery.getKey = (variables: BankingTransactionQueryVariables) => [
  'BankingTransaction',
  variables,
]
