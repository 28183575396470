import { useMutationOptionsWithInvalidation } from '@alice-financial/api'
import { InertHealthcheckItem, Button } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { useForm } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'
import { usePayrollInstructionsCompleteMutation } from './gql/payrollInstructionsComplete_gen'
import { getServerErrorHandler } from '../../../utils/forms/ServerError'
import { OrganizationDetailFragment } from '../../graphql/fragments/OrganizationFragment_gen'
import { useOrgDashboardQuery } from '../../orgDashboard/homepage/gql/orgDashboard_gen'

type PayrollInstructionsCompleteProps = {
  orgId: number
}

const PayrollInstructionsCompleteButton: React.FC<PayrollInstructionsCompleteProps> = ({ orgId }) => {
  const { setError } = useForm()
  const onError = getServerErrorHandler(setError)
  const mutationOptions = useMutationOptionsWithInvalidation({ onError }, [useOrgDashboardQuery.getKey()])
  const {
    mutate: payrollInstructionsComplete,
    isLoading,
    isSuccess,
  } = usePayrollInstructionsCompleteMutation(mutationOptions)

  const handleClick = () => {
    payrollInstructionsComplete({ input: { orgId } })
  }

  return (
    <Button onClick={handleClick} variant="contained" disabled={isLoading || isSuccess}>
      <FormattedMessage id="organization.onboarding.payroll_instructions_complete" />
    </Button>
  )
}

type PayrollConnectionHealthCheckProps = {
  org?: OrganizationDetailFragment | null
}
export const PayrollConnectionHealthCheck = ({ org }: PayrollConnectionHealthCheckProps) => {
  if (!org) return null
  const onboardingState = org.onboardingState
  if (onboardingState.isAlreadyLaunched) return null
  if (!onboardingState.isDoneWithPayrollInstructions) {
    return (
      <InertHealthcheckItem
        primary="Check your inbox for payroll setup instructions"
        secondary="When you've linked Alice to your payroll system, click on 'Payroll Connection Ready'."
        status="incomplete"
        action={<PayrollInstructionsCompleteButton orgId={org.id} />}
      />
    )
  }
  if (onboardingState.isDoneWithPayrollInstructions && !onboardingState.isPayrollConnected) {
    return (
      <InertHealthcheckItem
        primary="Payroll Information Submitted"
        secondary="Our team is currently reviewing your payroll information."
        status="pending"
      />
    )
  }
  if (onboardingState.isPayrollConnectionComplete) {
    return (
      <InertHealthcheckItem
        primary="Payroll Connected"
        secondary="All set! Alice is connected to your payroll."
        status="complete"
      />
    )
  }
  return null
}
