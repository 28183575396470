import { UseRestMutationCallbacks, fetchRest, useMutationWithInvalidation } from '@alice-financial/api'
import { useQueryClient } from '@tanstack/react-query'
import { AddressAttributes } from '../employeeDashboard/profile/address/types'
import { AddressEntity, EmployeeProfile, RestUser } from './types'
import { UserInput } from './types/UserInput'
import { USER_QUERY_KEY } from './useQueryUser'

const addressAttributesToProfileAddress = (
  addressAttributes: Omit<AddressAttributes, 'is_verified'> | undefined,
  currentAddress: AddressEntity
): AddressEntity => {
  if (!addressAttributes) return currentAddress
  const { line_1, line_2, city_name, state_code, zipcode } = addressAttributes
  return {
    ...currentAddress,
    line_1: line_1 ?? currentAddress.line_1,
    line_2: line_2 ?? currentAddress.line_2,
    city: city_name ?? currentAddress.city,
    state: state_code ?? currentAddress.state,
    zipcode: zipcode ?? currentAddress.zipcode,
  }
}

/**
 * Return a user object that would result from the `userInput` being
 * successfully merged in
 */
const getOptimisticUser = (userInput: UserInput, user: RestUser): RestUser => {
  const optimisticAddress = addressAttributesToProfileAddress(
    userInput.address_attributes,
    user.employee_profile.address
  )
  const employee_profile: EmployeeProfile = {
    ...user.employee_profile,
    address: optimisticAddress,
    employment_start_date: userInput.employment_start_date ?? user.employee_profile.employment_start_date,
  }
  // @TODO handle other parts of `userInput` other than address_attributes
  return {
    ...user,
    employee_profile,
  }
}

export const updateUser = (values: UserInput) =>
  fetchRest<RestUser>(`/api/user`, {
    method: 'PATCH',
    body: JSON.stringify(values),
  })

export const useUpdateUser = (
  mutationOptions?: UseRestMutationCallbacks<
    RestUser,
    UserInput,
    { previousUser: RestUser | undefined } | void
  >
) => {
  const queryClient = useQueryClient()
  return useMutationWithInvalidation(
    updateUser,
    {
      ...mutationOptions,
      onMutate: (values) => {
        mutationOptions?.onMutate && mutationOptions?.onMutate(values)
        // Snapshot the previous value from the user API object
        const previousUser = queryClient.getQueryData<RestUser>(USER_QUERY_KEY)

        if (!previousUser) return { previousUser: undefined }
        const optimisticUser = getOptimisticUser(values, previousUser)
        queryClient.setQueryData(USER_QUERY_KEY, () => optimisticUser)
        return { previousUser }
      },
      // If the mutation fails, use the context returned from onMutate to roll back
      onError: (err, newValues, context) => {
        mutationOptions?.onError && mutationOptions?.onError(err, newValues, context)
        if (context?.previousUser) {
          queryClient.setQueryData(USER_QUERY_KEY, context.previousUser)
        }
      },
      onSuccess: (data, newValues, context) => {
        queryClient.setQueryData(USER_QUERY_KEY, data)
        return mutationOptions?.onSuccess && mutationOptions?.onSuccess(data, newValues, context)
      },
    },
    'all'
  )
}
