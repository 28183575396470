import {
  EmployeeAccountStatus,
  EmployeeEnrollmentRequirementStatus as _Status,
} from '../graphql/generated.types'
import { useEmployeeEnrollmentInfoQuery } from './gql/employeeEnrollmentInfo_gen'
import { useReenrollmentStatus } from './useReenrollmentStatus'

const { Complete, Incomplete, Blocked } = _Status

const isIneligibleStatus = (accountStatus: EmployeeAccountStatus) =>
  accountStatus === EmployeeAccountStatus.Terminated || accountStatus === EmployeeAccountStatus.Ineligible

/**
 * enrollment flow is 'not_started' -> 'registered' -> 'spending_connected' -> 'active'
 *
 * why 'active' and not 'enrolled'? because the account status might be 'enrolled', but we
 * might still need to collect other enrollment-related information, e.g. employment start date
 *
 * re-enrollment flow is one of these:
 * 1. if EE originally enrolled via the web-apps: 'needs_reenrollment' -> 'active'
 * 2. if EE originally enrolled via Ada: 'needs_reenrollment_and_spending_connection' -> 'reenrolled_by_connecting_spending' -> 'active'
 *
 * (when #2 above the EE starts without any spending connections, so we take them to
 * get one, and per the REST API logic they're automatically re-enrolled when they
 * get one, so the 'reenrolled_by_connecting_spending' status is just a virtual-inner one
 * we add because we need to show a different UI for that case)
 *
 * Note that these states do not directly correspond to an employee's `accountStatus`
 */
export type EnrollmentCompletionStatus =
  | 'ineligible'
  | 'not_started'
  | 'registered'
  | 'spending_connected'
  | 'active'
  | 'needs_reenrollment'
  | 'needs_reenrollment_and_spending_connection'
  | 'reenrolled_by_connecting_spending'

// This string is the name of a localStorage key we used to identify when the EE
// has started re-enrollment without having a spending connection.
export const REENROLLMENT_STARTED_WITHOUT_SPENDING_CONNECTIONS =
  'reenrollmentStartedWithoutSpendingConnection'

/**
 * This hook derives enrollment-related state from user state - the main attributes
 * to look for are:
 * 1. employee.enrollmentInfo.accountStatus
 * 2. employee.enrollmentInfo.requirements
 * 3. employee.enrollmentInfo.enrollmentDate (reenrollment)
 * 4. localStorage flag (reenrollmentStartedWithoutSpendingConnection)
 */
export const useEnrollmentStatus = (): EnrollmentCompletionStatus | undefined => {
  const reenrollmentStatus = useReenrollmentStatus()
  const { data: employeeEnrollmentData, isLoading, isError } = useEmployeeEnrollmentInfoQuery()
  if (isLoading) return undefined // indeterminate state
  if (isError) return 'not_started'

  const employee = employeeEnrollmentData?.employee
  if (!employee) return 'not_started' // logged out - it's ok to show the enrollment flow

  const { accountStatus, requirements } = employee.enrollmentInfo

  if (isIneligibleStatus(accountStatus)) return 'ineligible'

  if (accountStatus === EmployeeAccountStatus.Enrolled) {
    // Short circuit - if user is enrolled, we ignore all other enrollment requirements
    return requirements.employmentStartDate === Complete ? 'active' : 'spending_connected'
  }

  if (requirements.registration === Blocked) return 'ineligible'
  if (requirements.registration === Incomplete) return 'not_started'
  if (requirements.phoneVerification !== Complete) return 'not_started'

  if (reenrollmentStatus) return reenrollmentStatus

  if (requirements.spendingConnection !== Complete) return 'registered'
  if (requirements.employmentStartDate !== Complete) return 'spending_connected'

  // user doesn't have accountStatus === EmployeeAccountStatus.Enrolled but has all other requirements
  return 'active'
}

export const useIsEnrolling = () => {
  const enrollmentStatus = useEnrollmentStatus()
  if (enrollmentStatus === undefined) return undefined
  return enrollmentStatus !== 'ineligible' && enrollmentStatus !== 'active'
}
