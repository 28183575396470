import * as Types from '../../../graphql/generated.types'

import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { fetchGql } from '@alice-financial/api'
export type ConnectedCardsQueryVariables = Types.Exact<{ [key: string]: never }>

export type ConnectedCardsQuery = {
  __typename?: 'Query'
  employee?: {
    __typename?: 'Employee'
    id: number
    spendingConnectionInfo: {
      __typename?: 'SpendingConnectionInfo'
      hasAcceptedAliceCardTos: boolean
      plaid: {
        __typename?: 'EmployeePlaid'
        linkSessions?: Array<{
          __typename?: 'PlaidLinkSession'
          id: number
          status: Types.PlaidLinkSessionStatusEnum
          createdAt: string
        }> | null
      }
      aliceCardAccounts?: Array<{
        __typename?: 'AliceCardAccount'
        id: number
        status: Types.AliceCardAccountStatus
        platform?: Types.AliceCardPlatform | null
        balanceMassTransit: number
        balanceParking: number
        balanceMonthStart: string
        usedParking: number
        usedMassTransit: number
        aliceCardEnrollment?: {
          __typename?: 'AliceCardEnrollment'
          enrolled: boolean
          enrolledAt?: string | null
          tosAcceptedAt?: string | null
        } | null
        aliceCards: Array<{
          __typename?: 'AliceCard'
          id: number
          last4: string
          createdAt: string
          status: Types.AliceCardStatus
          cardType: Types.AliceCardType
          activatedAt?: string | null
          expiryDate: string
          reportedMissingAt?: string | null
          issuingPlatform: Types.AliceCardIssuingPlatform
          bankingSystemId?: string | null
        }>
      }> | null
      bankingConnections?: Array<{
        __typename?: 'BankingConnection'
        id: number
        status: Types.BankingConnectionStatus
        platform?: Types.BankingAccountPlatform | null
        institution?: {
          __typename?: 'BankingInstitution'
          id: number
          name?: string | null
          platform: Types.BankingInstitutionPlaform
          preferredPlatform?: Types.BankingInstitutionPlaform | null
          platformId?: string | null
          unifiedInstitutionId?: number | null
        } | null
        bankingAccounts: Array<{
          __typename?: 'BankingAccount'
          id: number
          name?: string | null
          cardType?: Types.BankCardType | null
          accountType?: Types.BankingAccountType | null
          last4?: string | null
          platform: Types.BankingAccountPlatform
        }>
        activeCardShipment?: { __typename?: 'CardShipment'; id: number; shippedAt?: string | null } | null
      }> | null
    }
  } | null
}

export type AliceCardAccountFragment = {
  __typename?: 'AliceCardAccount'
  id: number
  status: Types.AliceCardAccountStatus
  platform?: Types.AliceCardPlatform | null
  balanceMassTransit: number
  balanceParking: number
  balanceMonthStart: string
  usedParking: number
  usedMassTransit: number
  aliceCardEnrollment?: {
    __typename?: 'AliceCardEnrollment'
    enrolled: boolean
    enrolledAt?: string | null
    tosAcceptedAt?: string | null
  } | null
  aliceCards: Array<{
    __typename?: 'AliceCard'
    id: number
    last4: string
    createdAt: string
    status: Types.AliceCardStatus
    cardType: Types.AliceCardType
    activatedAt?: string | null
    expiryDate: string
    reportedMissingAt?: string | null
    issuingPlatform: Types.AliceCardIssuingPlatform
    bankingSystemId?: string | null
  }>
}

export type AliceCardFragment = {
  __typename?: 'AliceCard'
  id: number
  last4: string
  createdAt: string
  status: Types.AliceCardStatus
  cardType: Types.AliceCardType
  activatedAt?: string | null
  expiryDate: string
  reportedMissingAt?: string | null
  issuingPlatform: Types.AliceCardIssuingPlatform
  bankingSystemId?: string | null
}

export type BankingConnectionFragment = {
  __typename?: 'BankingConnection'
  id: number
  status: Types.BankingConnectionStatus
  platform?: Types.BankingAccountPlatform | null
  institution?: {
    __typename?: 'BankingInstitution'
    id: number
    name?: string | null
    platform: Types.BankingInstitutionPlaform
    preferredPlatform?: Types.BankingInstitutionPlaform | null
    platformId?: string | null
    unifiedInstitutionId?: number | null
  } | null
  bankingAccounts: Array<{
    __typename?: 'BankingAccount'
    id: number
    name?: string | null
    cardType?: Types.BankCardType | null
    accountType?: Types.BankingAccountType | null
    last4?: string | null
    platform: Types.BankingAccountPlatform
  }>
  activeCardShipment?: { __typename?: 'CardShipment'; id: number; shippedAt?: string | null } | null
}

export type BankingInstitutionFragment = {
  __typename?: 'BankingInstitution'
  id: number
  name?: string | null
  platform: Types.BankingInstitutionPlaform
  preferredPlatform?: Types.BankingInstitutionPlaform | null
  platformId?: string | null
  unifiedInstitutionId?: number | null
}

export type BankingConnectionAccountFragment = {
  __typename?: 'BankingAccount'
  id: number
  name?: string | null
  cardType?: Types.BankCardType | null
  accountType?: Types.BankingAccountType | null
  last4?: string | null
  platform: Types.BankingAccountPlatform
}

export type LinkSessionFragment = {
  __typename?: 'PlaidLinkSession'
  id: number
  status: Types.PlaidLinkSessionStatusEnum
  createdAt: string
}

export const AliceCardFragmentDoc = `
    fragment AliceCard on AliceCard {
  id
  last4
  createdAt
  status
  cardType
  activatedAt
  expiryDate
  reportedMissingAt
  issuingPlatform
  bankingSystemId
}
    `
export const AliceCardAccountFragmentDoc = `
    fragment AliceCardAccount on AliceCardAccount {
  id
  status
  platform
  balanceMassTransit
  balanceParking
  balanceMonthStart
  usedParking
  usedMassTransit
  aliceCardEnrollment {
    enrolled
    enrolledAt
    tosAcceptedAt
  }
  aliceCards {
    ...AliceCard
  }
}
    `
export const BankingInstitutionFragmentDoc = `
    fragment BankingInstitution on BankingInstitution {
  id
  name
  platform
  preferredPlatform
  platformId
  unifiedInstitutionId
}
    `
export const BankingConnectionAccountFragmentDoc = `
    fragment BankingConnectionAccount on BankingAccount {
  id
  name
  cardType
  accountType
  last4
  platform
}
    `
export const BankingConnectionFragmentDoc = `
    fragment BankingConnection on BankingConnection {
  id
  status
  platform
  institution {
    ...BankingInstitution
  }
  bankingAccounts {
    ...BankingConnectionAccount
  }
  activeCardShipment {
    id
    shippedAt
  }
}
    `
export const LinkSessionFragmentDoc = `
    fragment LinkSession on PlaidLinkSession {
  id
  status
  createdAt
}
    `
export const ConnectedCardsDocument = `
    query ConnectedCards {
  employee {
    id
    spendingConnectionInfo {
      plaid {
        linkSessions {
          ...LinkSession
        }
      }
      hasAcceptedAliceCardTos
      aliceCardAccounts {
        ...AliceCardAccount
      }
      bankingConnections {
        ...BankingConnection
      }
    }
  }
}
    ${LinkSessionFragmentDoc}
${AliceCardAccountFragmentDoc}
${AliceCardFragmentDoc}
${BankingConnectionFragmentDoc}
${BankingInstitutionFragmentDoc}
${BankingConnectionAccountFragmentDoc}`

export const useConnectedCardsQuery = <TData = ConnectedCardsQuery, TError = Error>(
  variables?: ConnectedCardsQueryVariables,
  options?: UseQueryOptions<ConnectedCardsQuery, TError, TData>
) => {
  return useQuery<ConnectedCardsQuery, TError, TData>(
    variables === undefined ? ['ConnectedCards'] : ['ConnectedCards', variables],
    fetchGql<ConnectedCardsQuery, ConnectedCardsQueryVariables>(ConnectedCardsDocument, variables),
    options
  )
}

useConnectedCardsQuery.getKey = (variables?: ConnectedCardsQueryVariables) =>
  variables === undefined ? ['ConnectedCards'] : ['ConnectedCards', variables]
