import { fetchRest, useMutationWithInvalidation, UseRestMutationCallbacks } from '@alice-financial/api'
import { useResponseNotification } from '@alice-financial/pretext-ui'
import { IntlShape } from 'react-intl'
import { useNavigate } from 'react-router'
import { convertMoneyUnit } from '../../../../utils/formatters/moneyFormat'
import { useSpendingSummaryQuery } from '../gql/spendingSummary_gen'
import { isCategory } from '../txnUtils'
import { TRANSACTIONS_QUERY_KEY } from '../useTransactionsListQuery'
import { ApiMissingTransaction, MissingTransactionFormValues } from './types'

/**
 * The current missing transaction endpoint accepts an array of ApiMissingTransaction objects
 * - each of these has a string 'image' param that points to a separately-defined image file
 * param, e.g.
 *
 * {
 *   transactions: [{ ..., image: 'image_0' }],
 *   image_0: <File>
 * }
 *
 * However, we are uploading individual missing transactions one at a time rather than
 * in bulk, so we don't need to iterate over a list of input transactions to create the payload
 */

// this should be a Transaction or id, but it's just a confirmation message :/
type SubmitMissingTransactionResponse = string // JSON-serialized { notice: string }

const formValuesToFormData = (formValues: MissingTransactionFormValues): FormData => {
  const formData = new FormData()
  const { amount, pretax_category, date, payment_type, description, image } = formValues
  if (!isCategory(pretax_category)) throw new Error('must select pretax category') // shouldn't hit this, just making typesafe

  const centsAmount = convertMoneyUnit(parseFloat(amount), 'dollars')

  const imageFile = image && image[0]
  const imageRef = payment_type !== 'card' && imageFile ? 'image_0' : ''
  if (imageFile && imageRef) {
    formData.append(imageRef, imageFile)
  }
  const transactions: Array<ApiMissingTransaction> = [
    {
      amount: centsAmount,
      amount_eligible: centsAmount,
      description,
      pretax_category,
      date,
      is_cash: payment_type === 'cash' || payment_type === 'card-not-connected',
      is_partial_eligible: false,
      image: imageRef,
    },
  ]

  formData.append('transactions', JSON.stringify(transactions))
  return formData
}

export const useSubmitMissingTransaction = (intl: IntlShape) => {
  const navigate = useNavigate()
  const { notifySubmit, notifyError, notifySuccess } = useResponseNotification()
  const mutationOptions: UseRestMutationCallbacks<
    SubmitMissingTransactionResponse,
    MissingTransactionFormValues
  > = {
    onMutate: () =>
      notifySubmit(intl.formatMessage({ id: 'spending.missing_transaction.submitting_expense' })),
    onError: (error) => notifyError(error.message),
    onSuccess: (data) => {
      notifySuccess(JSON.parse(data).notice)
      navigate('/spending') // txn homepage - if we had a txn id in response, we could redirect to its details view
    },
  }
  return useMutationWithInvalidation(
    (formValues: MissingTransactionFormValues) => {
      const body = formValuesToFormData(formValues)
      return fetchRest<SubmitMissingTransactionResponse>(`/api/user/transactions/missing`, {
        method: 'POST',
        body,
      })
    },
    mutationOptions,
    [TRANSACTIONS_QUERY_KEY, useSpendingSummaryQuery.getKey()]
  )
}
