import { RestApiError, UseGqlMutationCallbacks, useMutationWithInvalidation } from '@alice-financial/api'
import { UseMutationOptions } from '@tanstack/react-query'
import { dateStringFormat } from '../../../../utils/formatters/dateFormat'
import { useMutationNotifier } from '../../../../utils/useMutationNotifier'
import { useEmployeeEnrollmentInfoQuery } from '../../../enrollment/gql/employeeEnrollmentInfo_gen'
import { TrackingEvent, useAnalytics } from '../../../telemetry/AnalyticsProvider'
import { useCurrentUserQuery } from '../../../user/gql/currentUser_gen'
import { useQueryUser, USER_QUERY_KEY } from '../../../user/useQueryUser'
import { updateUser } from '../../../user/useUpdateUser'
import { useEmployeeQuery } from '../../gql/employee_gen'
import { useConnectedCardsQuery } from '../gql/connectedCardsQuery_gen'
import {
  ProvisionAliceCardMutationVariables,
  useProvisionAliceCardMutation,
} from './gql/provisionAliceCard_gen'
import { OrderAliceCardFormValues } from './types'

type AliceCardUserInfoValues = Omit<OrderAliceCardFormValues, 'provisioning_scope'>
type UpdateAliceCardUserInfo = Omit<
  UseMutationOptions<unknown, RestApiError, AliceCardUserInfoValues, unknown>,
  'mutationKey'
>

/**
 * In order to order an Alice Card, a user needs:
 *
 * 1. An enrollment - the passed-in `accepted_tos` value allows the enrollment to be created if it
 *    doesn't already exist
 * 2. a birthdate
 * 3. verified-deliverable address
 *
 * This method ensures all prerequisites are met
 */
export const useUpdateAliceCardUserInfo = (mutationOptions: UpdateAliceCardUserInfo = {}) => {
  const { data: user } = useQueryUser()

  const updateBirthdate = (birthdateValue: string) => {
    const birthdate = dateStringFormat.monthFirstFromInputVal(birthdateValue) // birthdate is required - must exist for this to work
    return updateUser({ birthdate })
  }

  return useMutationWithInvalidation(
    (values) => {
      const ensureBirthdate =
        user?.birthdate === values.birthdate ? Promise.resolve() : updateBirthdate(values.birthdate)

      return ensureBirthdate
    },
    mutationOptions,
    [USER_QUERY_KEY, useCurrentUserQuery.getKey()]
  )
}

type UseProvisionAliceCardMutationOptions = UseGqlMutationCallbacks<
  unknown,
  ProvisionAliceCardMutationVariables,
  unknown
>
/**
 * This is a wrapper around the `useRequestAliceCard` mutation that accepts an
 * argument for what type of card to request/provision
 */
export const useRequestAliceCard = (mutationOptions: UseProvisionAliceCardMutationOptions = {}) => {
  const analytics = useAnalytics()
  const { mutateAsync: provisionAliceCard } = useProvisionAliceCardMutation()
  const mutationOptionsWithNotifications = useMutationNotifier({}, mutationOptions)

  const _mutationOptions: typeof mutationOptionsWithNotifications = {
    ...mutationOptionsWithNotifications,
    onMutate: (values) => {
      analytics.track(TrackingEvent.AliceCardRequested, {
        provisioning_scope: values.input.cardTypesRequested,
      })
      return mutationOptions.onMutate ? mutationOptions.onMutate(values) : undefined
    },
  }
  return useMutationWithInvalidation(provisionAliceCard, _mutationOptions, [
    useConnectedCardsQuery.getKey(),
    useEmployeeEnrollmentInfoQuery.getKey(),
    useEmployeeQuery.getKey(),
  ])
}
