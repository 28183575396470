import { ActionLayout, Button, Container, TextField, Typography } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { Control, Controller } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { PageBody } from '../../routes/PageBody'
import { useEnrollmentStatus } from '../useEnrollmentStatus'
import { ReenrollValues, useReenroll } from './useReenrollment'

interface ReenrollmentForm {
  control: Control<ReenrollValues>
  onSubmit: () => void
  disabled: boolean
}

export const ReenrollmentForm = ({ onSubmit, control, disabled }: ReenrollmentForm) => {
  const intl = useIntl()

  return (
    <form onSubmit={onSubmit} data-testid="reenroll-form">
      <Controller
        render={({ field, formState: { submitCount }, fieldState: { error, isTouched } }) => {
          const isError = Boolean((isTouched || submitCount > 0) && error?.message)
          return (
            <TextField
              {...field}
              type="password"
              autoComplete="new-password"
              variant="outlined"
              placeholder={intl.formatMessage({ id: 'auth.set_new_password.input_placeholder' })}
              autoFocus
              fullWidth
              error={isError}
              helperText={isError && error?.message}
              sx={{ marginBottom: '1.5rem' }}
            />
          )
        }}
        name="password"
        rules={{
          required: intl.formatMessage({ id: 'profile.password.validation.required' }),
          minLength: {
            value: 8,
            message: intl.formatMessage({ id: 'common.validation.minlength' }, { minLength: 8 }),
          },
        }}
        control={control}
      />

      <Typography variant="caption">
        * <FormattedMessage id="reenroll.landing.purchases_while_unenrolled_not_eligible" />
      </Typography>

      <ActionLayout
        primary={
          <Button variant="contained" fullWidth type="submit" disabled={disabled}>
            <FormattedMessage id="reenroll.landing.submit_reenrollment" />
          </Button>
        }
      />
    </form>
  )
}

export const Reenrollment = () => {
  const { onSubmit, control, isLoading, isSuccess } = useReenroll()
  const enrollmentStatus = useEnrollmentStatus()

  if (enrollmentStatus === undefined) {
    return null
  }

  return (
    <PageBody>
      <Container>
        <Typography variant="h1" textAlign="center" gutterBottom>
          <FormattedMessage id="reenroll.landing.welcome_back" />
        </Typography>
        <Typography variant="body1" gutterBottom>
          <FormattedMessage id="reenroll.landing.to_reenroll_password_update_required" />
        </Typography>

        <ReenrollmentForm onSubmit={onSubmit} control={control} disabled={isLoading || isSuccess} />
      </Container>
    </PageBody>
  )
}
