import { Container, InfoDrawer, Typography, useInfoDrawerControl } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { ContactSupport } from '../../ContactSupport'
import { FormattedMessage } from 'react-intl'

const ANCHOR_NAME = 'alice-card-balance-calculation'

type InfoContentProps = {
  onClickSupport: () => void
}
const InfoContent = ({ onClickSupport }: InfoContentProps) => (
  <>
    <Container>
      <Typography gutterBottom variant="body2">
        <FormattedMessage id="cards.alice.balance_calculation.explainer_1" />
      </Typography>
      <Typography gutterBottom variant="body2">
        <FormattedMessage id="cards.alice.balance_calculation.explainer_2" />
      </Typography>
      <Typography gutterBottom variant="body2">
        <FormattedMessage id="cards.alice.balance_calculation.explainer_3" />
      </Typography>
      <Typography variant="h3" gutterBottom>
        <FormattedMessage id="common.faq" />
      </Typography>
      <Typography variant="body2">
        <FormattedMessage id="cards.alice.balance_calculation.faq1q" />
      </Typography>
      <Typography variant="body2" gutterBottom>
        <FormattedMessage id="cards.alice.balance_calculation.faq1a" />
      </Typography>
      <Typography variant="body2">
        <FormattedMessage id="cards.alice.balance_calculation.faq2q" />
      </Typography>
      <Typography variant="body2" gutterBottom>
        <FormattedMessage id="cards.alice.balance_calculation.faq2a" />
      </Typography>
      <Typography variant="body2">
        <FormattedMessage id="cards.alice.balance_calculation.faq3q" />
      </Typography>
      <Typography variant="body2" gutterBottom>
        <FormattedMessage id="cards.alice.balance_calculation.faq3a" />
      </Typography>
    </Container>
    <ContactSupport onClick={onClickSupport} />
  </>
)

type BalanceCalculationInfoProps = {
  children: React.ReactNode
}
export const BalanceCalculationInfo = ({ children }: BalanceCalculationInfoProps) => {
  const { onClose } = useInfoDrawerControl(ANCHOR_NAME)
  return (
    <InfoDrawer
      title={
        <Typography variant="h2">
          <FormattedMessage id="cards.alice.balance_calculation.how_is_my_balance_calculated" />
        </Typography>
      }
      anchorName={ANCHOR_NAME}
      infoContent={<InfoContent onClickSupport={onClose} />}
    >
      {children}
    </InfoDrawer>
  )
}
