import {
  ApiRootException,
  GqlInputErrorException,
  composeMutationCallbacks,
  getGqlInputErrorHandler,
} from '@alice-financial/api'
import * as React from 'react'
import { useForm } from 'react-hook-form'
import { supportedLangToLanguagePref, useLocaleState } from '../../translations/LocaleProvider'
import { cleanTelString } from '../../utils/formatters/telFormat'
import { getServerErrorHandler } from '../../utils/forms/ServerError'
import { useTrackingContext } from '../telemetry/TrackingProvider'
import { EmployeeEnrollmentInfoQuery, useEmployeeEnrollmentInfoQuery } from './gql/employeeEnrollmentInfo_gen'
import { NonEditableRegisterInput, RegisterFormValues } from './types'
import { UseRegisterEmployeeOptions, useRegisterEmployee } from './useRegisterEmployee'

const getValues = (enrollmentData?: EmployeeEnrollmentInfoQuery): RegisterFormValues => {
  const user = enrollmentData?.currentUser
  const employee = enrollmentData?.employee
  const userHasSetPassword = user && !user.hasDummyPassword
  return {
    firstName: user?.firstName ?? '',
    lastName: user?.lastName ?? '',
    preferredName: user?.preferredName ?? '',
    email: user?.email ?? '',
    tel: cleanTelString(user?.tel?.nationalNumber ?? ''),
    acceptTermsAndEsign: Boolean(employee?.tosAcceptedAt),
    acceptAliceCardTos: Boolean(employee?.spendingConnectionInfo.hasAcceptedAliceCardTos),
    password: userHasSetPassword ? undefined : '', // `undefined` means password setting in registration is disabled
    passwordConfirmation: userHasSetPassword ? undefined : '',
  }
}

/**
 * This hook completes the registration step of the enrollment flow - it will
 * make the correct API calls to update the user or create the user
 */
export const useRegister = (
  nonEditableInput: NonEditableRegisterInput,
  mutationOptions?: UseRegisterEmployeeOptions
) => {
  const [language] = useLocaleState()
  const { data: enrollmentData } = useEmployeeEnrollmentInfoQuery()
  const values = getValues(enrollmentData)
  const { handleSubmit, ...form } = useForm<RegisterFormValues>({ values })
  const { setError } = form

  const serverErrorHandler = getServerErrorHandler(setError)
  const gqlInputErrorHandler = getGqlInputErrorHandler(setError, ['inviteCode', 'referredBy'])
  const _mutationOptions = composeMutationCallbacks(mutationOptions, {
    onError: (error) => {
      if (error instanceof GqlInputErrorException) {
        gqlInputErrorHandler(error)
        return
      }
      if (error instanceof ApiRootException) serverErrorHandler(error)
    },
  })

  const { mutate: registerEmployee, ...mutationState } = useRegisterEmployee(_mutationOptions)
  const [{ utmParams }] = useTrackingContext()

  const sendUserData = React.useCallback(
    (values: RegisterFormValues) => {
      const input = {
        ...values,
        language: supportedLangToLanguagePref(language),
        utmParams,
        ...nonEditableInput,
      }

      registerEmployee({ input })
    },
    [nonEditableInput, registerEmployee, utmParams, language]
  )

  return { onSubmit: handleSubmit(sendUserData), ...form, ...mutationState }
}
