import { List, Paper } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { OrganizationDetailFragment } from '../../graphql/fragments/OrganizationFragment_gen'
import { LaunchDateHealthcheck } from '../../organization/onboarding/LaunchDateHealthcheck'
import { PayrollConnectionHealthCheck } from '../../organization/onboarding/PayrollConnectionHealthCheck'
import { AliceCardProgramHealthcheck } from '../aliceCardProgram/AliceCardProgramHealthcheck'
import { BillingHealthcheck } from '../billing/BillingHealthcheck'
import { OrgLogoHealthcheck } from '../../organization/onboarding/OrgLogoHealthcheck'
import { OrgSurveyHealthcheck } from '../../organization/onboarding/OrgSurveyHealthcheck'

type OrgHealthcheckProps = {
  org: OrganizationDetailFragment
}

export const OrgHealthcheck = ({ org }: OrgHealthcheckProps) => {
  // the ordering logic is this:
  // 1a. PayrollConnectionHealthCheck - without this, you can't launch, as you have no payroll access, and you can't set billing, as we don't know how many employers you've got,
  //  and we apply this restriction for uniformity even if you're unified billing
  // 1b. OrgLogoHealthcheck - this isn't blocked by anything really, and is an easy lift for admins
  // 2a. BillingHealthcheck - next most important part of onboarding. This has to be done before launch, unless you've got a special dispensation
  // 2b. AliceCardProgramHealthcheck - we require new organizations to complete Alice Card setup before launch
  // 3. LaunchDateHealthcheck - once a user has completed the above, they're ready to set a launch date
  return (
    <Paper sx={{ mb: 3 }}>
      <List disablePadding>
        <PayrollConnectionHealthCheck org={org} />
        <OrgLogoHealthcheck org={org} />
        <OrgSurveyHealthcheck org={org} />
        <BillingHealthcheck org={org} />
        <AliceCardProgramHealthcheck org={org} />
        <LaunchDateHealthcheck org={org} />
      </List>
    </Paper>
  )
}
