import { HealthcheckItem } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { OrganizationDetailFragment } from '../../graphql/fragments/OrganizationFragment_gen'
import { useFormTemplatesQuery } from '../../forms/gql/templates_gen'

type OrgSurveyHealthcheckProps = {
  org?: OrganizationDetailFragment | null
}
export const OrgSurveyHealthcheck = ({ org }: OrgSurveyHealthcheckProps) => {
  const { data: formTemplatesData } = useFormTemplatesQuery({ templateType: 'org_survey' })
  if (!org) return null
  if (org.onboardingState.isAlreadyLaunched) return null
  if (!formTemplatesData?.formTemplate) return null // don't show this if there is no active template for the survey
  if (!org.onboardingState.isOrgOnboardingSurveyComplete) {
    return (
      <HealthcheckItem
        primary="Complete your organization onboarding survey"
        secondary="Tell us about your goals for Alice"
        status="incomplete"
        to="/onboard/org-survey"
      />
    )
  }
  return null
}
