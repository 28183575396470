import * as Types from '../generated.types'

export type WorkLocationFragment = {
  __typename?: 'WorkLocation'
  id: number
  nickname?: string | null
  name: string
  latitude?: number | null
  longitude?: number | null
  logo?: { __typename?: 'Image'; url?: string | null; thumbnailUrl?: string | null } | null
  enrollmentMaterials?: { __typename?: 'EnrollmentMaterials'; zippedMaterialsUrl?: string | null } | null
  address: {
    __typename?: 'AddressDisplay'
    isMailing: boolean
    isVerified: boolean
    line1: string
    line2?: string | null
    cityName: string
    stateCode: string
    zipcode: string
  }
}

export const WorkLocationFragmentDoc = `
    fragment WorkLocation on WorkLocation {
  id
  nickname
  name
  latitude
  longitude
  logo {
    url
    thumbnailUrl
  }
  enrollmentMaterials {
    zippedMaterialsUrl
  }
  address {
    isMailing
    isVerified
    line1
    line2
    cityName
    stateCode
    zipcode
  }
}
    `
