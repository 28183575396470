import {
  Accordion,
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
  Box,
  Container,
  Typography,
} from '@alice-financial/pretext-ui'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Elements } from '@stripe/react-stripe-js'
import * as React from 'react'
import { PaymentCardInput } from '../../externalServices/stripe/PaymentCardInput'
import { useBillingStripe } from '../../externalServices/stripe/useLoadStripe'
import { ACHBillingSourceInput } from './ACHBillingSourceInput'
import { ConnectBillingSourceProps, Billable } from './types'
import { useBillingClientSecret } from './useBillingClientSecret'
import { ACHMandate } from '../../externalServices/stripe/ACHMandate'

type PaymentOptionProps = ConnectBillingSourceProps &
  Pick<AccordionProps, 'expanded' | 'disabled' | 'onChange'>
const PaymentCardOption = ({
  billable,
  onSuccess,
  ...accordionProps
}: Omit<PaymentOptionProps, 'currentBillingSource'>) => (
  <Accordion {...accordionProps}>
    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
      <Typography variant="body2" color="primary" fontWeight="bold">
        Enter credit card details
      </Typography>
    </AccordionSummary>
    <AccordionDetails>
      <PaymentCardInput billable={billable} onSuccess={onSuccess} />
    </AccordionDetails>
  </Accordion>
)

/* 
  "Secret" component for re-accepting ACH mandate. Not clear yet how often we'll want or need to do this,
  so for now it will remain hidden and only used by Alicians. If a customer finds this, no real harm, just will be confusing.
  Only applicable if current payment method is not null AND is a US bank account.
*/
type SecretACHMandateReacceptanceProps = { billable: Billable; clientSecret: string }
const SecretACHMandateReacceptance = ({ billable, clientSecret }: SecretACHMandateReacceptanceProps) => {
  const [showMandate, setShowMandate] = React.useState(false)
  const clickShowMandate = () => {
    setShowMandate(true)
  }
  return (
    <>
      <Typography color="#fff" onClick={clickShowMandate} variant="caption">
        π
      </Typography>
      {showMandate && (
        <ACHMandate
          clientSecret={clientSecret}
          handleConfirmationResponse={() => {
            setShowMandate(false)
          }}
          invalidationKeys={[useBillingClientSecret.getKey(billable)]}
        />
      )}
    </>
  )
}

const BankAccountOption = ({
  billable,
  clientSecret,
  currentBillingSource,
  onSuccess,
  onBankSelected,
  ...accordionProps
}: PaymentOptionProps & { clientSecret: string; onBankSelected: () => void }) => {
  return (
    <Accordion {...accordionProps}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
        <Typography variant="body2" color="primary" fontWeight="bold">
          Enter bank account details
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <ACHBillingSourceInput
          currentBillingSource={currentBillingSource}
          clientSecret={clientSecret}
          billable={billable}
          onSuccess={onSuccess}
          onBankSelected={onBankSelected}
        />
        {currentBillingSource && (
          <SecretACHMandateReacceptance billable={billable} clientSecret={clientSecret} />
        )}
      </AccordionDetails>
    </Accordion>
  )
}

type PaymentOption = 'card' | 'bank_credentials'
export const ConnectBillingSource = ({
  currentBillingSource,
  billable,
  onSuccess,
}: ConnectBillingSourceProps) => {
  const [expandedOption, setExpandedOption] = React.useState<PaymentOption | null>(null)
  const [isBankSelected, setIsBankSelected] = React.useState(false)
  const stripePromise = useBillingStripe(billable?.billing?.billingWorkspace)
  const { data: clientSecret } = useBillingClientSecret(billable, currentBillingSource)

  if (!clientSecret) return null

  return (
    <>
      <Container>
        <Typography variant="body2" gutterBottom>
          Choose how you would like to connect the account we should bill for <strong>{billable.name}</strong>
        </Typography>
        <Box pb={3}>
          <Elements stripe={stripePromise} options={{ clientSecret }}>
            {!isBankSelected && (
              <PaymentCardOption
                billable={billable}
                onSuccess={onSuccess}
                expanded={expandedOption === 'card'}
                onChange={(_, isExpanded) => setExpandedOption(isExpanded ? 'card' : null)}
              />
            )}
            <BankAccountOption
              currentBillingSource={currentBillingSource}
              billable={billable}
              clientSecret={clientSecret}
              onSuccess={() => {
                onSuccess()
                setIsBankSelected(false) // once new bank account is connected, allow card entry again
              }}
              expanded={expandedOption === 'bank_credentials' || isBankSelected}
              onChange={(_, isExpanded) =>
                setExpandedOption(isExpanded && !isBankSelected ? 'bank_credentials' : null)
              }
              onBankSelected={() => setIsBankSelected(true)} // bank connected but ACH mandate not accepted
            />
          </Elements>
        </Box>
      </Container>
    </>
  )
}
