import aliceCardholderAgreement from 'bundle-text:../docs/AliceConsumerCardholderAgreement.html' // using Parcel bundle-inlining
import aliceCustodialAgreement from 'bundle-text:../docs/AliceCustodialAccountAgreement.html' // using Parcel bundle-inlining
import esignAgreement from 'bundle-text:../docs/EsignConsent.html' // using Parcel bundle-inlining
import * as React from 'react'
import { Navigate, Outlet, RouteObject, RouterProvider } from 'react-router'
import { ScrollRestoration, createBrowserRouter } from 'react-router-dom'
import { ViewTracker } from '../../utils/analytics/ViewTracker'
import { Authenticate } from '../auth/AuthenticateRoutes'
import { ForgotPassword } from '../auth/ForgotPassword'
import { Login } from '../auth/Login'
import { Logout } from '../auth/Logout'
import { ResetPassword } from '../auth/ResetPassword'
import { DocRoute } from '../docs/DocRoute'
import { FetchDocRoute } from '../docs/FetchDocRoute'
import { FetchRemoteDocRoute } from '../docs/FetchRemoteDocRoute'
import { employeeDashboardRoutes } from '../employeeDashboard/employeeDashboardRoutes'
import { EnrollmentLanding } from '../enrollment/EnrollmentLanding'
import { EnrollmentRouter } from '../enrollment/EnrollmentRouter'
import { FindEnrollmentCode } from '../enrollment/FindEnrollmentCode'
import { InactiveEmployer } from '../enrollment/InactiveEmployer'
import { NewEnrollmentSummary } from '../enrollment/NewEnrollmentSummary'
import { ReenrolledByConnectingSpending } from '../enrollment/reenrollment/ReenrolledByConnectingSpending'
import { Reenrollment } from '../enrollment/reenrollment/Reenrollment'
import { Register } from '../enrollment/Register'
import { TrackEnrollmentCode } from '../enrollment/TrackEnrollmentCode'
import { Home } from '../home/Home'
import { OrgOnboarding } from '../organization/onboarding/OrgOnboarding'
import { OrderFormPage } from '../organization/onboarding/steps/OrderFormPage'
import { orgDashboardRoutes } from '../orgDashboard/orgDashboardRoutes'
import { TrackAccountStatus } from '../telemetry/TrackAccountStatus'
import { TrackUserId } from '../telemetry/TrackUserId'
import { TrackUtmParams } from '../telemetry/TrackUtmParams'
import { CustodialAccountAgreement } from '../terms/CustodialAccountAgreement'
import { UserWebRouteObject, normalizeRoute } from './normalizeRoutes'
import { NotFound } from './NotFound'
import { ProdFirewall } from './ProdFirewall'
import { RouteChrome } from './RouteChrome'
import { OrgSurvey } from '../organization/onboarding/forms/OrgSurvey'

const RouteRoot = () => (
  <ProdFirewall>
    <ViewTracker>
      <TrackUtmParams />
      <TrackUserId />
      <TrackAccountStatus />
      <ScrollRestoration />
      <Outlet />
    </ViewTracker>
  </ProdFirewall>
)

const docsRoutes: Array<RouteObject> = [
  {
    path: 'esign-consent',
    element: <DocRoute docHtml={esignAgreement} docName="esign_consent" />,
  },
  {
    path: 'tos-employee',
    element: <FetchDocRoute src="/tos_employee" docName="terms_and_conditions" />,
  },
  {
    path: 'tos-organization',
    element: <FetchDocRoute src="/tos_organization" />,
  },
  {
    path: 'privacy_policy',
    element: <FetchDocRoute src="/privacy_policy" />,
  },
  { path: 'alice-cardholder', element: <DocRoute docHtml={aliceCardholderAgreement} /> },
  { path: 'alice-card-custodial', element: <DocRoute docHtml={aliceCustodialAgreement} /> },
  { path: ':docId', element: <FetchRemoteDocRoute /> },
]

const enrollmentRoutes: Array<RouteObject> = [
  { index: true, element: <FindEnrollmentCode /> },
  {
    path: ':enrollmentCode/:referredById?',
    element: <TrackEnrollmentCode />,
    children: [
      {
        index: true,
        element: <EnrollmentLanding />,
      },
      { path: 'register', element: <Register /> },
      { path: 'inactive', element: <InactiveEmployer /> },
    ],
  },
  {
    path: 'summary',
    element: (
      <Authenticate requiredProfile="employee">
        <NewEnrollmentSummary />
      </Authenticate>
    ),
  },
  {
    path: 'reenroll',
    children: [
      {
        index: true,
        element: (
          <Authenticate requiredProfile="employee">
            <Reenrollment />
          </Authenticate>
        ),
      },
      {
        path: 'spending_just_connected',
        element: (
          <Authenticate requiredProfile="employee">
            <ReenrolledByConnectingSpending />
          </Authenticate>
        ),
      },
    ],
  },
]

const routeConfig: Array<RouteObject> = [
  {
    element: <RouteRoot />,
    children: [
      {
        element: <RouteChrome type="user" />,
        children: [
          { path: 'login', element: <Login /> },
          { path: 'forgot-password', element: <ForgotPassword /> },
          { path: 'reset-password/:resetToken', element: <ResetPassword /> },
          {
            path: 'logout',
            element: (
              <Authenticate>
                <Logout />
              </Authenticate>
            ),
          },
        ],
      },
      {
        element: <RouteChrome />,
        children: [
          {
            index: true,
            element: (
              <Authenticate>
                <Home />
              </Authenticate>
            ),
          },
          { path: '*', element: <NotFound /> },
          { path: 'tos', element: <CustodialAccountAgreement /> }, // must keep this route stable
          { path: 'docs', children: docsRoutes },
          {
            path: 'enrollment-flow/*', // DML or no-longer-supported route
            element: <Navigate to="/enroll" replace />,
          },
          {
            path: 'enroll',
            element: <EnrollmentRouter />,
            children: enrollmentRoutes,
          },
          {
            path: 'onboard',
            children: [
              {
                index: true,
                element: <Navigate to="new" replace />,
              },
              {
                path: 'new',
                element: <OrgOnboarding />,
              },
              {
                path: 'order-form',
                element: <OrderFormPage />,
              },
              {
                path: 'org-survey/:page?',
                element: <OrgSurvey />,
              },
              {
                path: ':provisionalOrgId',
                element: (
                  <Authenticate requiredProfile="provisional_admin">
                    <OrgOnboarding />
                  </Authenticate>
                ),
              },
            ],
          },
        ],
      },
      employeeDashboardRoutes,
      orgDashboardRoutes,
    ],
  },
]
const routes: Array<UserWebRouteObject> = routeConfig.map(normalizeRoute)

export const Router = () => {
  return <RouterProvider router={createBrowserRouter(routes)} />
}
