import {
  Box,
  Button,
  CheckboxController,
  FormLabel,
  Grid,
  Link,
  TextFieldController,
  Typography,
} from '@alice-financial/pretext-ui'
import * as React from 'react'
import { useForm } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { AliceCardTosLabel } from '../../../enrollment/AliceCardTosLabel'
import {
  EmployeeEnrollmentInfoQuery,
  useEmployeeEnrollmentInfoQuery,
} from '../../../enrollment/gql/employeeEnrollmentInfo_gen'
import { CardRequestTypeEnum } from '../../../graphql/generated.types'
import { useSearchParamsRedirect } from '../../../routes/routeUtils'
import { useAddressComponents } from '../../profile/address/useAddressComponents'
import { OrderAliceCardFormValues } from './types'
import { useRequestAliceCard, useUpdateAliceCardUserInfo } from './useRequestAliceCard'

const getUserValues = (
  enrollmentData?: EmployeeEnrollmentInfoQuery
): Omit<OrderAliceCardFormValues, 'provisioningScope'> => {
  const tosAccepted = Boolean(enrollmentData?.employee?.spendingConnectionInfo.hasAcceptedAliceCardTos)
  const birthdate = enrollmentData?.currentUser?.birthdate || ''

  return {
    tosAccepted,
    birthdate,
  }
}

const useFormValues = (provisioningScope: CardRequestTypeEnum): OrderAliceCardFormValues => {
  const { data: enrollmentData } = useEmployeeEnrollmentInfoQuery()
  return React.useMemo(
    // memoize, becuase every new return value will reset the form
    () => ({
      ...getUserValues(enrollmentData),
      provisioningScope,
    }),
    [enrollmentData, provisioningScope]
  )
}

export type RequestFormProps = { scope: CardRequestTypeEnum }
/**
 * This form handles the request of Instant and Physical Alice Cards.
 * It will ensure that the user's birthdate and address have been
 * populated before submitting.
 */
export const RequestAliceCardForm = ({ scope }: RequestFormProps) => {
  const intl = useIntl()
  const {
    error,
    content,
    action,
    contenteditable: isEditingAddress,
    onSubmit: onSubmitAddress,
  } = useAddressComponents({
    doVerify: true,
    actionButtonProps: { size: 'medium', suffix: <FormattedMessage id="common.address" /> },
  })

  const requireAddress = scope !== CardRequestTypeEnum.Instant
  const doSubmitAddressVerification = isEditingAddress && requireAddress

  const values = useFormValues(scope)
  const { handleSubmit, control } = useForm({ values })

  const redirect = useSearchParamsRedirect('/cards')
  const {
    mutate: requestAliceCard,
    isLoading: isRequestingAliceCard,
    isSuccess,
  } = useRequestAliceCard({
    onSuccess: () => redirect(),
  })

  // convenience wrapper around requestAliceCard that extracts birthdate from form values
  const orderAliceCard = ({ birthdate: _birthdate, ...input }: OrderAliceCardFormValues) =>
    requestAliceCard({ input: { ...input, cardTypesRequested: scope } })

  const { mutate: updateUserAndOrderCard, isLoading: isUpdatingUserInfo } = useUpdateAliceCardUserInfo({
    onSuccess: (_data, values) => orderAliceCard(values),
  })
  const request = scope === CardRequestTypeEnum.Instant ? orderAliceCard : updateUserAndOrderCard
  // when editing address, the form updates & verifies the mailing addres, otherwise it requests card
  const onSubmit = doSubmitAddressVerification ? onSubmitAddress : handleSubmit((values) => request(values))
  const isSubmitDisabled = isUpdatingUserInfo || isRequestingAliceCard || isSuccess || isEditingAddress

  return (
    <form onSubmit={onSubmit} data-testid="request-alice-card">
      {scope !== 'instant' && (
        <>
          <FormLabel htmlFor="birthdate">
            <FormattedMessage id="profile.birthdate.label" />
          </FormLabel>
          <TextFieldController
            name="birthdate"
            required={!isEditingAddress}
            control={control}
            type="date"
            sx={{ display: 'block' }}
          />

          <Typography gutterBottom>
            <FormattedMessage id="cards.alice.send_my_card_to" />
          </Typography>
          {content}
          {error}
        </>
      )}

      <CheckboxController
        color="primary"
        name="tosAccepted"
        control={control}
        rules={{
          validate: (v) =>
            v === true || intl.formatMessage({ id: 'cards.alice.confirm_you_read_the_documents' }),
        }}
        label={<AliceCardTosLabel />}
      />
      <Grid container spacing={2} flexDirection="row" justifyContent="flex-end" sx={{ p: 5 }}>
        <Box textAlign="center" component={Grid} item xs={12} sm="auto">
          {!isEditingAddress && (
            <Button variant="text" color="secondary" component={Link} to="/cards">
              <FormattedMessage id="common.cancel.never_mind" />
            </Button>
          )}
          {requireAddress && action}
        </Box>

        {!isEditingAddress && (
          <Box textAlign="right" component={Grid} item xs={12} sm={'auto'}>
            <Button type="submit" variant="contained" color="primary" disabled={isSubmitDisabled} fullWidth>
              <FormattedMessage id="cards.alice.order_my_alice_card" />
            </Button>
          </Box>
        )}
      </Grid>
    </form>
  )
}
