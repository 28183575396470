import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Link,
  List,
  ListItem,
  ListItemText,
  SvgIcon,
  Typography,
} from '@alice-financial/pretext-ui'
import AddCardIcon from '@mui/icons-material/AddCard'
import CommuteIcon from '@mui/icons-material/Commute'
import ReceiptIcon from '@mui/icons-material/Receipt'
import SavingsIcon from '@mui/icons-material/Savings'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { SwitchLanguage } from '../../translations/LocalizationProvider'
import { PageBody } from '../routes/PageBody'
import { TrackingEvent, useAnalytics } from '../telemetry/AnalyticsProvider'
import { useCurrentUserQuery } from '../user/gql/currentUser_gen'
import { useEnrollmentEmployer } from './useEnrollmentEmployer'

type LandingListItemProps = { Icon: typeof SvgIcon; children: React.ReactNode }
const LandingListItem = ({ Icon, children }: LandingListItemProps) => (
  <ListItem disableGutters>
    <Grid container justifyContent="center" alignItems="center" spacing={2}>
      <Grid item>
        <Icon fontSize="large" color="primary" />
      </Grid>
      <Grid item xs={10}>
        {children}
      </Grid>
    </Grid>
  </ListItem>
)

export const EnrollmentLanding = () => {
  const analytics = useAnalytics()
  const { data: employerData } = useEnrollmentEmployer()
  const employer = employerData?.enrollmentEmployer
  const { data: currentUserData } = useCurrentUserQuery()
  const user = currentUserData?.currentUser
  const employerName = employer?.marketingFriendlyName
  return (
    <PageBody>
      <Container>
        <Typography
          variant="h1"
          gutterBottom
          textAlign="center"
          fontWeight={500}
          maxWidth={500}
          marginX="auto"
        >
          <FormattedMessage id="enroll.landing.get_ready_to_save" />
        </Typography>

        <Typography gutterBottom data-testid="enrollment-employer">
          <FormattedMessage id="enroll.landing.alice_has_partnered_with" values={{ employerName }} />
        </Typography>

        {employer && employer.enrolledCount > 1 && (
          <Typography gutterBottom>
            <FormattedMessage
              id="enroll.landing.num_employees_enrolled"
              values={{ employerName, employeeCount: employer.enrolledCount }}
            />
          </Typography>
        )}

        <Box my={3} component={Divider} />
        <Typography variant="h2" textAlign="center">
          <FormattedMessage id="common.how_it_works" />
        </Typography>
        <List dense sx={{ paddingBottom: '2rem' }}>
          <LandingListItem Icon={AddCardIcon}>
            <ListItemText primary={<FormattedMessage id="enroll.landing.connect_your_card" />} />
          </LandingListItem>
          <LandingListItem Icon={CommuteIcon}>
            <ListItemText primary={<FormattedMessage id="enroll.landing.get_ac_and_spend" />} />
          </LandingListItem>
          <LandingListItem Icon={ReceiptIcon}>
            <ListItemText primary={<FormattedMessage id="enroll.landing.then_alice_sets_election" />} />
          </LandingListItem>
          <LandingListItem Icon={SavingsIcon}>
            <ListItemText primary={<FormattedMessage id="enroll.landing.then_alice_reduces_taxes" />} />
          </LandingListItem>
        </List>
        <Typography gutterBottom>
          <FormattedMessage id="enroll.landing.you_get_benefit" />
        </Typography>

        <Grid container justifyContent="center" rowSpacing={2} sx={{ marginTop: 3 }}>
          <Grid container item xs={12} justifyContent="center">
            <SwitchLanguage />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              fullWidth
              variant="contained"
              size="large"
              color="primary"
              disabled={!employer}
              component={Link}
              to="register"
              onClick={() => analytics.track(TrackingEvent.EnrollmentStart)}
            >
              <FormattedMessage id="common.start" />
            </Button>
            {!user && (
              <Typography variant="caption" component="p" textAlign="center" sx={{ marginTop: 1 }}>
                <FormattedMessage id="enroll.register.if_account_then_sign_in" />
              </Typography>
            )}
          </Grid>
        </Grid>
      </Container>
    </PageBody>
  )
}
