import React from 'react'
import {
  EmployeeAccountStatus,
  EmployeeEnrollmentRequirementStatus as _Status,
} from '../graphql/generated.types'
import { useEmployeeEnrollmentInfoQuery } from './gql/employeeEnrollmentInfo_gen'
import { REENROLLMENT_STARTED_WITHOUT_SPENDING_CONNECTIONS } from './useEnrollmentStatus'

const { Complete, Incomplete } = _Status

/**
 * This function tells if the user has started the re-enrollment process without
 * any spending connections. That's is used at `_useEnrollmentStatus` to compute the
 * EE enrollment status. Whenever the user starts the re-enrollment process without
 * a spending connection, we set a localStorage flag to true. Such localStorage flag
 * is used because we don't have another way (like a backend flag) to know if the
 * EE started the re-enrollment process without a spending connection (no dummy
 * password, for example, given that a real password was set on the first enrollment).
 */
export const _useIsOrStartedReenrollmentWithoutSpendingConnections = () => {
  const { data: enrollmentInfoData } = useEmployeeEnrollmentInfoQuery()
  const enrollmentInfo = enrollmentInfoData?.employee?.enrollmentInfo

  const hasToOrStartedReenrollmentWithoutSpendingConnections =
    enrollmentInfo?.accountStatus === EmployeeAccountStatus.NotEnrolled &&
    Boolean(enrollmentInfo?.enrollmentDate) &&
    enrollmentInfo.requirements.spendingConnection === Incomplete

  React.useEffect(() => {
    if (window.localStorage.getItem(REENROLLMENT_STARTED_WITHOUT_SPENDING_CONNECTIONS) === 'true') return // set once, never unset by this hook
    if (hasToOrStartedReenrollmentWithoutSpendingConnections) {
      window.localStorage.setItem(REENROLLMENT_STARTED_WITHOUT_SPENDING_CONNECTIONS, 'true')
    }
  }, [hasToOrStartedReenrollmentWithoutSpendingConnections])

  return (
    hasToOrStartedReenrollmentWithoutSpendingConnections ||
    window.localStorage.getItem(REENROLLMENT_STARTED_WITHOUT_SPENDING_CONNECTIONS)
  )
}

export const useReenrollmentStatus = () => {
  const reenrollingWithoutSpendingConnection = _useIsOrStartedReenrollmentWithoutSpendingConnections()
  const { data: employeeEnrollmentData } = useEmployeeEnrollmentInfoQuery()
  const employee = employeeEnrollmentData?.employee
  if (!employee) return undefined

  const { accountStatus, requirements, enrollmentDate } = employee.enrollmentInfo

  // If the EE's account status is "not_enrolled" and they have an enrollment date,
  // that indicates the EE was enrolled at some point, something happened (they
  // were terminated or on leave and now they're back and went to the "not_enrolled"
  // status), so they need to re-enroll.
  // The other reason for having to go through the reenrollment flow is when the
  // EE record is one transferred from another org (and the old record was in an
  // inactive status ex. terminated, on leave, etc). Technically, from the EE record
  // perspective is not a reenrollment (since the EE record itself doesn't have a record of
  // a past reenrollment), but from a user perspective they have to reenroll at
  // Alice to keep using it from their new org. That allows us to update the EE
  // record status to "enrolled" (since it starts on "not_enrolled").
  const mustReenroll =
    accountStatus === EmployeeAccountStatus.NotEnrolled &&
    (enrollmentDate || requirements.spendingConnection === Complete)

  if (mustReenroll) {
    if (reenrollingWithoutSpendingConnection) {
      return 'needs_reenrollment_and_spending_connection'
    }
    return 'needs_reenrollment'
  }

  // if the EE re-enrolled starting without spending connections, then added at
  // least one (per guard condition next above), then we signal that by the status next
  if (reenrollingWithoutSpendingConnection) return 'reenrolled_by_connecting_spending'

  return undefined
}
