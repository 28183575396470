import * as Types from '../../../graphql/generated.types'

import { PageInfoFragmentDoc } from '../../../graphql/fragments/PageInfoFragment_gen'
import { EmployeeInfoFragmentDoc } from '../../../graphql/fragments/EmployeeInfoFragment_gen'
import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { fetchGql } from '@alice-financial/api'
export type OrgEmployeeListQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>
  after?: Types.InputMaybe<Types.Scalars['String']['input']>
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>
  before?: Types.InputMaybe<Types.Scalars['String']['input']>
  role?: Types.InputMaybe<Types.EmployeeRole>
  searchTerms?: Types.InputMaybe<Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input']>
  accountStatuses?: Types.InputMaybe<Array<Types.EmployeeAccountStatus> | Types.EmployeeAccountStatus>
  employerId?: Types.InputMaybe<Types.Scalars['Int']['input']>
}>

export type OrgEmployeeListQuery = {
  __typename?: 'Query'
  organization?: {
    __typename?: 'Organization'
    employees: {
      __typename?: 'EmployeeConnection'
      totalCount: number
      pageInfo: {
        __typename?: 'PageInfo'
        startCursor?: string | null
        endCursor?: string | null
        hasNextPage: boolean
        hasPreviousPage: boolean
      }
      nodes?: Array<{
        __typename?: 'Employee'
        id: number
        employmentStartDate?: string | null
        role: Types.EmployeeRole
        enrollmentInfo: { __typename?: 'EmployeeEnrollment'; accountStatus: Types.EmployeeAccountStatus }
        user: {
          __typename?: 'User'
          id: number
          email?: string | null
          firstName?: string | null
          lastName?: string | null
        }
        paygroupInfo: { __typename?: 'EmployerInfo'; id: number; name?: string | null }
      } | null> | null
    }
  } | null
}

export const OrgEmployeeListDocument = `
    query OrgEmployeeList($first: Int, $after: String, $last: Int, $before: String, $role: EmployeeRole, $searchTerms: [String!], $accountStatuses: [EmployeeAccountStatus!], $employerId: Int) {
  organization {
    employees(
      first: $first
      after: $after
      last: $last
      before: $before
      role: $role
      searchTerms: $searchTerms
      accountStatuses: $accountStatuses
      employerId: $employerId
    ) {
      totalCount
      pageInfo {
        ...PageInfo
      }
      nodes {
        ...EmployeeInfo
      }
    }
  }
}
    ${PageInfoFragmentDoc}
${EmployeeInfoFragmentDoc}`

export const useOrgEmployeeListQuery = <TData = OrgEmployeeListQuery, TError = Error>(
  variables?: OrgEmployeeListQueryVariables,
  options?: UseQueryOptions<OrgEmployeeListQuery, TError, TData>
) => {
  return useQuery<OrgEmployeeListQuery, TError, TData>(
    variables === undefined ? ['OrgEmployeeList'] : ['OrgEmployeeList', variables],
    fetchGql<OrgEmployeeListQuery, OrgEmployeeListQueryVariables>(OrgEmployeeListDocument, variables),
    options
  )
}

useOrgEmployeeListQuery.getKey = (variables?: OrgEmployeeListQueryVariables) =>
  variables === undefined ? ['OrgEmployeeList'] : ['OrgEmployeeList', variables]
