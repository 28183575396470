import {
  ApiRootException,
  fetchRest,
  useMutationWithInvalidation,
  UseRestMutationCallbacks,
} from '@alice-financial/api'
import { useCurrentUserQuery } from '../../../user/gql/currentUser_gen'
import { AddressEntity } from '../../../user/types'
import { USER_QUERY_KEY } from '../../../user/useQueryUser'
import { updateUser } from '../../../user/useUpdateUser'
import { AddressFormValues } from './types'
import { useEmployeeQuery } from '../../gql/employee_gen'

const ensureVerifiedAddress = (address: AddressEntity | null) => {
  if (!address?.is_verified) {
    return Promise.reject(new ApiRootException({ error: 'Address could not be verified' }, '/api/addresses'))
  }
  return address
}

export const verifyAddress = ({ line_1, line_2, city_name, state_code, zipcode }: AddressFormValues) => {
  const searchParams = new URLSearchParams({ line_1, line_2: line_2 || '', city_name, state_code, zipcode })
  return fetchRest<AddressEntity>(`/api/addresses/verify_new_address?${searchParams}`)
}

type AddressUpdater = (values: AddressFormValues) => Promise<AddressEntity>
const updateUserAddress: AddressUpdater = ({ line_1, line_2, city_name, state_code, zipcode }) =>
  updateUser({ address_attributes: { line_1, line_2, city_name, state_code, zipcode } }).then(
    (user) => user.employee_profile.address
  )

const updateUserAddressWithValidation: AddressUpdater = ({
  line_1,
  line_2,
  city_name,
  state_code,
  zipcode,
}) =>
  fetchRest<AddressEntity>('/api/addresses', {
    method: 'PATCH',
    body: JSON.stringify({ line_1, line_2, city_name, state_code, zipcode }),
  }).then(ensureVerifiedAddress)

export const addressValuesFromProfileAddress = (profileAddress: AddressEntity): AddressFormValues => {
  const { line_1, line_2, city, state, zipcode } = profileAddress
  return {
    line_1,
    line_2,
    city_name: city,
    state_code: state,
    zipcode,
  }
}

/**
 * Update the address for the signed-in user, optionally verifying as a deliverable
 * address according to the API
 */
export const useUpdateAddress = (
  mutationOptions: UseRestMutationCallbacks<AddressEntity, AddressFormValues> = {},
  doVerify = false
) =>
  useMutationWithInvalidation(
    doVerify ? updateUserAddressWithValidation : updateUserAddress,
    mutationOptions,
    [useCurrentUserQuery.getKey(), USER_QUERY_KEY, useEmployeeQuery.getKey()]
  )
