import * as React from 'react'
import { matchPath, Navigate, Outlet, useLocation, useMatch } from 'react-router'
import { useIsDemoUser } from '../user/useIsDemoUser'
import { useInactiveEmployerRedirect } from './useEnrollmentEmployer'
import { EnrollmentCompletionStatus, useEnrollmentStatus } from './useEnrollmentStatus'

type AllowedPaths = [string, ...Array<string>]
type DefaultPath = string
/**
 * This is a map from the EnrollmentCompletionStatus provided by `useEnrollmentStatus`
 * to a tuple of
 *
 * [
 *   AllowedPaths, // paths that are allowed for the user's current enrollment status
 *   DefaultPath   // the path to redirect to if the user is not on an allowed path
 * ]
 */
const getEnrollmentRedirect = (enrollmentStatus: EnrollmentCompletionStatus): [AllowedPaths, DefaultPath] => {
  switch (enrollmentStatus) {
    case 'active':
    case 'ineligible':
      // enrollment paths should not be accessed - go to dashboard
      return [['/'], '/']
    case 'needs_reenrollment_and_spending_connection':
    case 'registered':
      // If the EE `needs_reenrollment_and_spending_connection` then we need them
      // to add a spending connection (a personal card).
      // By taking re-enrolling EEs to `/cards/personal` we ensure that they get
      // to the right page (rather than taking them to `/cards` whose redirect doesn't work
      // right for EEs re-enrolling who had an Alice card - the `useHasAliceCards` hook will
      // keep the EEs in the `/cards` route instead of taking them to the `/cards/personal`)
      return [['/cards/*'], '/cards/']
    case 'spending_connected':
      return [['/cards/*', '/enroll/summary'], '/enroll/summary']
    case 'needs_reenrollment':
      return [['/enroll/reenroll'], '/enroll/reenroll']
    case 'reenrolled_by_connecting_spending':
      return [['/enroll/reenroll/spending_just_connected'], '/enroll/reenroll/spending_just_connected']
    case 'not_started':
      return [['/enroll/*'], '/enroll']
  }
}

/**
 * If the user has not completed enrollment requirements, we need to send them to the appropriate
 * location when they land on the enrollment landing page.
 *
 * `undefined` === redirect has not been determined yet
 * String === path of recommended redirect
 * `null` === no redirect recommended
 */
const useEnrollmentRedirect = () => {
  const enrollmentStatus = useEnrollmentStatus()
  useInactiveEmployerRedirect()
  const isDemo = useIsDemoUser()
  const [allowedPaths = [], entryPath] = enrollmentStatus ? getEnrollmentRedirect(enrollmentStatus) : []
  const currentLocation = useLocation()
  const isOnAllowedPath = Boolean(allowedPaths.find((path) => matchPath(path, currentLocation.pathname)))

  if (!enrollmentStatus) return undefined
  if (enrollmentStatus === 'active' && isDemo) return '/spending' // redirect to /spending for demo once user is enrolled

  if (isOnAllowedPath) return null

  return entryPath
}
/**
 * This component wraps enrollment-related routes in order to ensure that the user is sent
 * to the enrollment route that is directly related to their current completion status.
 *
 * _Note_: This router should _only_ be used in enrollment routes
 *
 * In order to use `EnrollmentRouter` outside of the enrollment-related, e.g. to redirect all users
 * to a particular enrollment view from anywhere in the application, `allowedPath` would need to
 * know more about 'unenrolled' paths, which is probably not a good idea.
 *
 * We prevent mis-use by just throwing an error if the router is called from outside `/enroll/*`
 */
export const EnrollmentRouter = () => {
  const isInEnrollment = Boolean(useMatch('/enroll/*'))
  if (!isInEnrollment) {
    throw new Error('EnrollmentRouter used outside of enrollment route')
  }

  const enrollmentRedirect = useEnrollmentRedirect()

  if (enrollmentRedirect === undefined) return null

  if (enrollmentRedirect) {
    return <Navigate to={enrollmentRedirect} replace />
  }

  return <Outlet />
}
